/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Link, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getClient } from '../../../lib/info';
import { getAppointment } from '../../../utils/appointment';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { createIcs } from '../../../api/create-ics';

export const MenuNew = (): JSX.Element => {
  const appointment = getAppointment();
  const {
    company: { webSiteLink = '' },
  } = getClient();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  let link = webSiteLink;

  if (!link.includes('http')) {
    link = `https://${webSiteLink}`;
  }

  const getGoogleCalendar = useCallback((): void => {
    const start = moment(appointment?.startTime, 'YYYYMMDDTHHmmssZ');

    const end = start
      .clone()
      .add(appointment?.durationValue ?? 10, 'minutes')
      .format('YYYYMMDDTHHmmssZ');
    window.open(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${
        appointment?.pointOfSaleName
      } - ${appointment?.serviceName ?? ''}&dates=${start.format(
        'YYYYMMDDTHHmmssZ'
      )}/${end}&location=${appointment?.pointOfSaleName} - ${
        appointment?.pointOfSaleAddress
      }, ${appointment?.pointOfSaleCity}&details=${
        appointment?.serviceDescription ?? ''
      }`
    );
  }, []);

  const getYahooCalendar = useCallback((): void => {
    const start = moment(appointment?.startTime, 'YYYY-MM-DDTHH:mm:ss');
    const end = start
      .clone()
      .add(appointment?.durationValue ?? 10, 'minutes')
      .format('YYYYMMDDTHHmmssZ');
    window.open(
      `https://calendar.yahoo.com/?v=60&type=20&title=${
        appointment?.pointOfSaleName
      } - ${appointment?.serviceName ?? ''}&ST=${start.format(
        'YYYYMMDDTHHmmssZ'
      )}&ET=${end}&desc=${appointment?.serviceDescription ?? ''}&in_loc=${
        appointment?.pointOfSaleName
      } - ${appointment?.pointOfSaleAddress}, ${appointment?.pointOfSaleCity}`
    );
  }, []);

  const getOutlookCalendar = useCallback((): void => {
    const start = moment(appointment?.startTime, 'YYYY-MM-DDTHH:mm:ss');
    const end = start
      .clone()
      .add(appointment?.durationValue ?? 10, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');

    window.open(
      `https://outlook.office.com/owa/?path=/calendar/action/compose&subject=${
        appointment?.pointOfSaleName
      } - ${appointment?.serviceName ?? ''}&location=${
        appointment?.pointOfSaleName
      } - ${appointment?.pointOfSaleAddress}, ${
        appointment?.pointOfSaleCity
      }&startdt=${start.format('YYYY-MM-DDTHH:mm:ss')}&enddt=${end}&body=${
        appointment?.serviceDescription ?? ''
      }`
    );
  }, []);

  const addToCalendar = useCallback(async (): Promise<void> => {
    if (appointment !== undefined) {
      try {
        const endDate = moment(appointment?.startTime, 'YYYY-MM-DDTHH:mm:ss')
          .add(appointment?.durationValue ?? 10, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss');

        const event = await createIcs({
          ...appointment,
          webSiteLink: link,
          endDate,
        });
        if (event !== undefined) {
          const blob = new Blob([event], {
            type: 'text/calendar;charset=utf-8',
          });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        }
        // saveAs(blob, 'event-schedule.ics')
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const MenuContainer = useMemo(() => {
    return (
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => await addToCalendar()}
          key="download-ics"
          value="download-ics"
          style={{
            minWidth: ref?.current?.clientWidth ?? 'auto',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        >
          Scarica ics
        </MenuItem>
        <Divider style={{ margin: '0' }} />
        <MenuItem
          onClick={() => getGoogleCalendar()}
          key="google-calendar"
          value="google-calendar"
          style={{
            minWidth: ref?.current?.clientWidth ?? 'auto',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        >
          Google calendar
        </MenuItem>
        <Divider style={{ margin: '0' }} />
        <MenuItem
          onClick={() => getYahooCalendar()}
          key="yahoo"
          value="yahoo"
          style={{
            minWidth: ref?.current?.clientWidth ?? 'auto',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        >
          Yahoo
        </MenuItem>
        <Divider style={{ margin: '0' }} />
        <MenuItem
          onClick={() => getOutlookCalendar()}
          key="outlook"
          value="outlook"
          style={{
            minWidth: ref?.current?.clientWidth ?? 'auto',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        >
          Outlook
        </MenuItem>
      </Menu>
    );
  }, [ref, open, handleClose, anchorEl]);

  return (
    <div>
      <Link
        ref={ref}
        id="fade-button"
        onClick={handleClick}
        style={{
          // textDecoration: 'underline',
          color: theme.palette.primary.main,
          paddingLeft: 0,
          paddingRight: 0,
          fontWeight: 700,
          cursor: 'pointer',
        }}
        color="primary"
      >
        <Trans>{'Aggiungi al calendario'}</Trans>
      </Link>

      {MenuContainer}
    </div>
  );
};
