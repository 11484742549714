/* eslint-disable @typescript-eslint/no-unused-vars */
import './recap.css';

import { Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { ImieiDati, Service, When, Where } from './items';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ConfirmButton } from '../../components/confirmButton';
import { Mask } from '../../components/Mask';
import { getIndexOfStep } from '../../lib/info';
import { selectedAppointmentSlot } from '../../store/appointment-slot';
import { selectedType } from '../../store/appointment-type';
import { getAppointmentId, increment, setPage } from '../../store/page';
import { selectedPersonalData } from '../../store/personal-data';
import { selectedPointOfSale, selectedStudio } from '../../store/point-of-sale';
import { selectedUser } from '../../store/user';
import { setAppointment } from '../../utils/appointment';
import { eraseWhen } from '../../utils/appointment/when';
import { useAppointment } from '../../api/appointment';

export const Recap = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(selectedType);
  const where = useAppSelector(selectedPointOfSale);
  const studio = useAppSelector(selectedStudio);
  const user = useAppSelector(selectedUser);
  const appointmentSlot = useAppSelector(selectedAppointmentSlot);
  const personalData = useAppSelector(selectedPersonalData);
  const appointmentId = useAppSelector(getAppointmentId);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(async () => {
    eraseWhen();
    setOpen(true);
    const startDate = moment(
      `${appointmentSlot?.date ?? ''} ${appointmentSlot?.hour ?? ''}:00`,
      'YYYY-MM-DD hh:mm:ss'
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = await useAppointment({
      serviceId: type.id,
      pointOfSaleId: where.id,
      personalData,
      startTime: startDate.format('YYYY-MM-DDTHH:mm:ss'),
      endTime: startDate
        .clone()
        .add(type.durationValue ?? 10, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss'),
      userId: user?.id,
      studioId: studio,
      appointmentId,
    });
    setOpen(false);
    if (result !== undefined) {
      setAppointment({
        appointmentId: result,
        serviceId: type.id,
        serviceName: type.name,
        serviceDescription: type.description,
        pointOfSaleId: where.id,
        pointOfSaleName: where.name,
        pointOfSaleAddress: where.address,
        pointOfSaleCity: where.city,
        pointOfSaleEmail: where?.email,
        pointOfSaleTelephone: where?.telephone,
        latitude: where?.latitude,
        longitude: where?.longitude,
        userId: user?.id,
        userName: user?.name,
        userSurname: user?.surname,
        name: personalData?.name,
        surname: personalData.surname,
        email: personalData.email,
        telephone: personalData.phone,
        startTime: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        description: personalData.otherInfo,
        durationValue: type.durationValue ?? 10,
        isOnlineBooking: type.isOnlineBooking ?? false,
      });
      dispatch(increment());
    }
  }, [
    appointmentId,
    appointmentSlot?.date,
    appointmentSlot?.hour,
    dispatch,
    personalData,
    studio,
    type.description,
    type.durationValue,
    type.id,
    type.isOnlineBooking,
    type.name,
    user?.id,
    user?.name,
    user?.surname,
    where.address,
    where.city,
    where?.email,
    where.id,
    where?.latitude,
    where?.longitude,
    where.name,
    where?.telephone,
  ]);

  const Titles = useMemo(() => {
    return (
      <div className="titlePage padding-bottom-s">
        <div>
          <Typography variant="contentTitle1">
            <Trans>pages.recap.title</Trans>
          </Typography>
        </div>
        <div>
          <Typography variant="contentTitle1" fontWeight="400">
            <Trans>pages.recap.title1</Trans>
          </Typography>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="default-page">
      {open && <Mask />}
      {Titles}
      <Service
        type={type}
        action={() => dispatch(setPage(getIndexOfStep('appuntamenti')))}
      />
      <Where
        where={where}
        action={() => dispatch(setPage(getIndexOfStep('dove')))}
      />
      <When
        appointmentSlot={appointmentSlot}
        user={user}
        action={() => dispatch(setPage(getIndexOfStep('quando')))}
      />
      <ImieiDati
        personalData={personalData}
        action={() => dispatch(setPage(getIndexOfStep('i-miei-dati')))}
      />

      <ConfirmButton
        title="pages.recap.button"
        disabled={false}
        action={async () => await handleClick()}
      />
    </div>
  );
};
