/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AppointmentData } from '../interface/appointment-data';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';

interface CreateIcsProps extends AppointmentData {
  webSiteLink: string;
}

export const createIcs = async ({
  appointmentId,
  serviceName: title,
  serviceDescription: description,
  pointOfSaleName,
  pointOfSaleAddress,
  pointOfSaleCity,
  pointOfSaleEmail,
  name,
  surname,
  email,
  webSiteLink,
  startTime: startDate,
  endDate,
  latitude,
  longitude,
}: CreateIcsProps): Promise<string | undefined> => {
  try {
    const { data } = await client.post(`/api/Email/Ics`, {
      appointmentId,
      title,
      description,
      pointOfSaleName,
      pointOfSaleAddress,
      pointOfSaleCity,
      pointOfSaleEmail,
      name,
      surname,
      email,
      webSiteLink,
      startDate,
      endDate,
      latitude,
      longitude,
    });
    return data;
  } catch (err) {
    console.trace(err);
  }
  return undefined;
};
