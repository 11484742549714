import { Paper } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import { If, Then } from 'react-if';
import { Outlet, useLocation } from 'react-router-dom';
import { getClient } from '../../lib/info';
import { Header } from '../header';
import { Controllers } from '../../lib/routes/controllers';
import { useAppSelector } from '../../store/hooks';
import { selectedPage } from '../../store/page';
import { Footer } from '../footer';
import { ProgressBar } from '../progressBar';

export const Layout: FC<{ customPath?: boolean }> = memo(function Layout({
  customPath = false,
}): JSX.Element {
  const client = getClient();

  return (
    <div className="App">
      <GetStepData />
      <Controllers customPath={customPath} />
      <div className="header">
        <If condition={Boolean(!(client.iFrame ?? false))}>
          <Then>
            <Header />
          </Then>
        </If>
      </div>
      <Paper
        id="blu-paper-body"
        elevation={0}
        className={`paper ${client?.iFrame ?? false ? 'padding-iframe' : ''}`}
        data-testid="body"
      >
        <ProgressBar customPath />
        <div className="body padding-top-m" id="body">
          {/* <If condition={client.iFrame}>
            <Then>
              <IframeHeight />
            </Then>
          </If> */}

          <Outlet />
        </div>
      </Paper>
      <If condition={Boolean(!(client.iFrame ?? false))}>
        <Then>
          <div className="footer">
            <Footer company={client.company} />
          </div>
        </Then>
      </If>
    </div>
  );
});

export const LayoutConfirm = memo(function LayoutConfirm(): JSX.Element {
  const client = getClient();
  useEffect(() => {
    const delayFn = setTimeout(() => {
      window.parent.postMessage(
        {
          step: 'conferma',
        },
        '*'
      );
    }, 50);

    return () => {
      clearTimeout(delayFn);
    };
  }, []);
  return (
    <div className="App">
      <div className="header">
        <If condition={Boolean(!(client.iFrame ?? false))}>
          <Then>
            <Header special={true} />
          </Then>
        </If>
      </div>
      <Paper
        id="blu-paper-body"
        elevation={0}
        className={`paper ${client?.iFrame ?? false ? 'padding-iframe' : ''}`}
        data-testid="body"
      >
        <div className="body" id="body">
          {/* <ProgressBar /> */}
          {/* <If condition={client.iFrame}>
            <Then>
              <IframeHeight />
            </Then>
          </If> */}
          <Outlet />
        </div>
      </Paper>
      <If condition={Boolean(!(client.iFrame ?? false))}>
        <Then>
          <div className="footer">
            <Footer company={client.company} />
          </div>
        </Then>
      </If>
    </div>
  );
});

export const LayoutOtherPages = memo(function LayoutOtherPages(): JSX.Element {
  const client = getClient();
  const location = useLocation();
  useEffect(() => {
    const delayFn = setTimeout(() => {
      let name = 'modifica';
      if (location.pathname.includes('privacy')) {
        name = 'privacy policy';
      } else if (location.pathname.includes('terms')) {
        name = 'informativa sul trattamento dei dati personali';
      } else if (location.pathname.includes('cookie')) {
        name = 'cookie policy';
      } else if (location.pathname.includes('cancella')) {
        name = 'cancella';
      }

      window.parent.postMessage(
        {
          step: name,
        },
        '*'
      );
    }, 50);

    return () => {
      clearTimeout(delayFn);
    };
  }, []);
  return (
    <div className="App">
      {/* <GetStepData /> */}
      <div className="header">
        <If condition={Boolean(!(client.iFrame ?? false))}>
          <Then>
            <Header />
          </Then>
        </If>
      </div>
      <Paper
        id="blu-paper-body"
        elevation={0}
        className="body padding-top-s"
        data-testid="body"
      >
        <Outlet />
      </Paper>
      <If condition={Boolean(!(client.iFrame ?? false))}>
        <Then>
          <div className="footer">
            <Footer company={client.company} />
          </div>
        </Then>
      </If>
    </div>
  );
});

export const IframeHeight = memo(function Test() {
  let height = 0;

  const [tempHeight, setTempHeight] = useState<number>(0);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      window.parent.postMessage(tempHeight, '*');
      height = tempHeight;
    }, 50);

    return () => {
      clearTimeout(delayFn);
    };
  }, [tempHeight]);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const progressBar = document.getElementById('blu-progress-bar');

      const value =
        (entries[0].target as HTMLElement).offsetHeight +
        (progressBar !== undefined ? progressBar?.clientHeight ?? 0 : 0);

      if (value !== height) {
        setTempHeight(value);
      }
    });
    const body = document.getElementById('body');
    // start observing a DOM node
    if (body !== null) resizeObserver.observe(body);
  }, []);
  return <></>;
});

export const GetStepData = memo(function GetStepData() {
  const page = useAppSelector(selectedPage);
  const { steps } = getClient();

  useEffect(() => {
    const delayFn = setTimeout(() => {
      window.parent.postMessage(
        {
          source: 'Blu Booking',
          step: steps[page],
        },
        '*'
      );
    }, 50);

    return () => {
      clearTimeout(delayFn);
    };
  }, [page]);

  return <></>;
});
