/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './unified-container.css';

import { Link, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { Trans } from 'react-i18next';
import { Else, If, Then } from 'react-if';

interface UnifiedRecapProps {
  stepTitle: string;
  title: string[];
  description?: Array<{
    text: string;
    link?: string;
    adorment?: string;
  }>;
  icon?: JSX.Element;
  actionTitle?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  action?: Function;
  children?: JSX.Element;
}
export const UnifiedRecap = memo(function UnifiedRecap({
  stepTitle,
  title,
  description = [],
  icon,
  actionTitle,
  action,
  children,
}: UnifiedRecapProps): JSX.Element {
  return (
    <div className="unified-container padding-top-xm padding-bottom-s">
      <div className="info">
        <div className="icon">
          {icon}
          <Typography
            variant="contentTitle2"
            fontWeight={400}
            style={{ paddingLeft: '10px' }}
          >
            <Trans>{stepTitle}</Trans>
          </Typography>
        </div>
        <div className="title">
          {title.map((t) => (
            <div key={nanoid()}>
              <Typography variant="contentTitle2" fontWeight="700">
                {t}
              </Typography>
            </div>
          ))}
        </div>

        {description?.map((desc) => (
          <div key={nanoid()}>
            <If condition={desc?.link !== undefined && desc?.link !== ''}>
              <Then>
                <If
                  condition={
                    desc?.adorment !== undefined && desc?.adorment !== ''
                  }
                >
                  <Then>
                    <Typography variant="contentDescription1">
                      {desc.adorment}
                    </Typography>
                  </Then>
                </If>

                <Link
                  variant="contentDescription1"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  href={desc?.link ?? ''}
                  target="_blank"
                >
                  {desc.text}
                </Link>
              </Then>
              <Else>
                <Typography variant="contentDescription1">
                  {desc.text}
                </Typography>
              </Else>
            </If>
          </div>
        ))}
      </div>
      <If condition={actionTitle !== undefined}>
        <Then>
          <div className="change">
            <Link
              variant="link"
              color="primary"
              fontSize="1rem"
              onClick={() => (action !== undefined ? action() : {})}
              style={{ cursor: 'pointer' }}
            >
              <Trans>{actionTitle}</Trans>
            </Link>
          </div>
        </Then>
      </If>

      <If condition={children !== undefined}>
        <Then>
          <div className="change">{children}</div>
        </Then>
      </If>
    </div>
  );
});
