/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable multiline-ternary */
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import RoomIcon from '@mui/icons-material/Room';
import { Typography, Link } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { motion } from 'framer-motion';
import { memo, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { If, Then } from 'react-if';

import { PointOfSaleInfo } from './point-of-sale-info';
import { PointOfSale } from '../../../../interface';
import { Rectangle } from '../../../../components/rectangle';
import { numberFormatter } from '../../../../utils/formatter';

const variantsIn = {
  initial: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
};

const alreadyOpened = {
  initial: {
    height: 'auto',
    opacity: 1,
  },
  animate: {
    height: 'auto',
    opacity: 1,
  },
};

const variantsOUT = {
  initial: {
    height: 'auto',
    opacity: 1,
  },
  animate: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
};

interface PointOfSaleContainerProps {
  pointOfSale: PointOfSale;
  index: number;
  change: Function;
  selected: string;
}
export const PointOfSaleContainer = memo(function PointOfSaleContainer({
  pointOfSale,
  change,
  selected,
  index,
}: PointOfSaleContainerProps) {
  const [opened, setOpened] = useState<number>(pointOfSale?.open ?? -1);

  const Details = useMemo(() => {
    return (
      <>
        {opened !== -1 && (
          <motion.div
            key={nanoid()}
            variants={
              opened < 1
                ? variantsOUT
                : opened === 2
                ? alreadyOpened
                : variantsIn
              // alreadyOpened
            }
            initial="initial"
            animate="animate"
          >
            <PointOfSaleInfo key={nanoid()} pointOfSale={pointOfSale} />
          </motion.div>
        )}
      </>
    );
  }, [opened, pointOfSale]);

  const Content = useMemo(
    () => (
      <div className="point-of-sale">
        <div style={{ padding: '0 20px' }}>
          <PointOfSaleDescription pointOfSale={pointOfSale} />
          <PointOfSaleButton
            pointOfSale={pointOfSale}
            change={(pos: PointOfSale) => change(pos)}
            openInfo={(event: Event) => {
              event.stopPropagation();
              setOpened(opened < 1 ? 1 : 0);
              pointOfSale = { ...pointOfSale, open: opened < 1 ? 2 : -1 };
            }}
          />
        </div>

        {Details}
      </div>
    ),
    [opened]
  );

  return (
    <div id={`point-of-sale-${pointOfSale.id}`}>
      <Rectangle
        active={selected === pointOfSale.id}
        action={(event: Event) => {
          // event.stopPropagation()
          change(pointOfSale);
        }}
        // action={() => setOpened(opened < 1 ? 1 : 0)}
      >
        {Content}
      </Rectangle>
    </div>
  );
});

interface PointOfSaleDescriptionProps {
  pointOfSale: PointOfSale;
}
const PointOfSaleDescription = memo(function PointOfSaleDescription({
  pointOfSale: { name, distance, address, city, latitude, longitude },
}: PointOfSaleDescriptionProps): JSX.Element {
  return (
    <>
      <div style={{ paddingBottom: '5px' }}>
        <Typography variant="contentDescription1" fontWeight="700">
          {name}
        </Typography>
      </div>
      <If
        condition={
          address !== null &&
          address !== undefined &&
          city !== null &&
          city !== undefined
        }
      >
        <Then>
          <div className="pos-description">
            <DirectionsOutlinedIcon
              color="primary"
              style={{ fontSize: '25px' }}
            />
            <div className="pos-text">
              <div className="pos-text-inner">
                <Typography variant="contentDescription1">
                  {`${address ?? ''} - ${city ?? ''}`}
                </Typography>
              </div>
            </div>
          </div>
        </Then>
      </If>
      <If condition={distance !== null && distance !== undefined}>
        <Then>
          <div className="pos-description">
            <RoomIcon
              width="22px"
              height="22px"
              color="primary"
              style={{ fontSize: '25px' }}
            />
            <div className="pos-text">
              <div className="pos-text-inner">
                <Typography variant="contentDescription2">
                  {`${numberFormatter(distance ?? 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })} Km da te`}
                </Typography>
              </div>
            </div>
          </div>
        </Then>
      </If>
    </>
  );
});

interface PointOfSaleButtonProps {
  change: Function;
  openInfo: Function;
  pointOfSale: PointOfSale;
}
const PointOfSaleButton = memo(function PointOfSaleButton({
  change,
  openInfo,
  pointOfSale,
}: PointOfSaleButtonProps): JSX.Element {
  return (
    <div className="pos-buttons padding-bottom-xs">
      <div
        style={{
          display: 'flex',
          height: 'fit-content',
          width: 'fit-content',
          gap: '20px',
        }}
      >
        <Link
          variant="contentDescription2"
          fontWeight={700}
          color="primary"
          style={{ padding: '5px', cursor: 'pointer' }}
          onClick={(event) => openInfo(event)}
        >
          <Trans>pages.where.info</Trans>
        </Link>
        {/* <Button
          variant="contained"
          style={{
            width: 'fit-content',
            padding: '2px 15px 2px 15px',
            borderRadius: '8px'
          }}
          onClick={(event) => {
            event.stopPropagation()
            change(pointOfSale)
          }}
          disableElevation
        >
          <Typography variant="contentDescription2" fontWeight={700}>
            <Trans>pages.where.point_of_sale_button</Trans>
          </Typography>
        </Button> */}
      </div>
    </div>
  );
});
