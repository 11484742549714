import './App.css';
import './lib/style/global.css';
import './lib/style/spacing.css';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment';
import { PopUp } from './components/PopUp';
import { AppRoutes } from './lib/routes';
import { ScrollToTop } from './lib/routes/controllers';
import { BannerCookie } from './components/Banner';
import { getClient, getTheme } from './lib/info';

export const App = (): JSX.Element => {
  moment.locale('it');
  const { iFrame } = getClient();
  // const { height, ref } = useResizeDetector()
  // let h = 0
  // useEffect(() => {
  //   if (iFrame !== null && iFrame) {
  //     console.log(height)
  //     if (height !== undefined && height !== h) {
  //       window.parent.postMessage(height, '*')
  //       h = height
  //     }
  //   }
  //   // window.postMessage(height?.current?.clientHeight, window.parent.location)
  // }, [height])
  return (
    <div style={{ padding: iFrame ?? false ? '0 10px' : 0 }} id="Test">
      <ThemeProvider theme={getTheme()}>
        {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
        <PopUp />
        <CssBaseline />
        <AppRoutes />
        <ScrollToTop />
        {!(iFrame ?? false) && <BannerCookie />}
      </ThemeProvider>
    </div>
  );
};
