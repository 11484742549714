import './index.css';
import './lib/language';

import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { store } from './store';
import { queryClient } from './lib/react-query';
import { onInit } from './utils/init';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
onInit().then(async (data) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById('root')!;
  const root = createRoot(container);
  console.log('DATA', data);

  root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
      {/* </React.StrictMode> */}
    </Provider>
  );
});
