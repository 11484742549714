/* eslint-disable react-hooks/rules-of-hooks */
import './recaps.css';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RoomIcon from '@mui/icons-material/Room';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, Theme, Typography, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { getClient, getIndexOfStep, getRecaps } from '../../../lib/info';
import { selectedAppointmentSlot } from '../../../store/appointment-slot';
import { selectedType } from '../../../store/appointment-type';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setPage } from '../../../store/page';
import { selectedPointOfSale } from '../../../store/point-of-sale';
import { selectedUser } from '../../../store/user';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Else, If, Then } from 'react-if';

interface RecapsProps {
  index: number;
}
export const Recaps = memo(function Recaps({
  index,
}: RecapsProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const Content = useMemo(() => {
    const data = getRecaps(index).map((page) => (
      <SingleRecap
        key={nanoid()}
        page={page}
        theme={theme}
        action={() => dispatch(setPage(getIndexOfStep(page)))}
      />
    ));

    return <>{data}</>;
  }, [index, theme]);

  return (
    <div className="recess-recap padding-bottom-s padding-top-xs">
      {Content}
    </div>
  );
});

interface SingleRecapProps {
  page: string;
  theme: Theme;
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: Function;
}
const SingleRecap = memo(function SingleRecap({
  page,
  theme,
  action,
}: SingleRecapProps): JSX.Element {
  const {
    company: { pointOfSale },
    customTheme,
  } = getClient();
  let text = '';
  let icon: JSX.Element | null = null;
  if (page === 'appuntamenti') {
    const { name: type } = useAppSelector(selectedType);
    icon = <VisibilityIcon color="primary" style={{ fontSize: '30px' }} />;
    text = type;
  } else if (page === 'dove') {
    icon = <RoomIcon color="primary" style={{ fontSize: '30px' }} />;
    const { name: where, address, city } = useAppSelector(selectedPointOfSale);
    text = `${where} - ${address} - ${city}`;
  } else if (page === 'quando') {
    icon = <CalendarMonthIcon color="primary" style={{ fontSize: '30px' }} />;
    const { date, hour } = useAppSelector(selectedAppointmentSlot);
    const user = useAppSelector(selectedUser);
    text = ` ${moment(date, 'YYYY/MM/DD').format('DD MMMM YYYY')} alle ore ${
      hour ?? ''
    }`;
    if (
      user?.name !== undefined &&
      user?.name !== '' &&
      user?.surname !== undefined &&
      user?.surname !== ''
    ) {
      text = `${text} con ${user?.name ?? ''} ${user?.surname ?? ''}`;
    }
  }
  return (
    <div
      className="single-recap"
      style={{
        backgroundColor: theme.palette.card.main,
        border: `${customTheme?.rectangle?.borderWidth ?? 1}px solid ${
          theme.palette.card.border ?? ''
        }`,
        // border: `1px solid ${theme.palette.card.border ?? ''}`,
        borderRadius: customTheme?.rectangle?.borderRadius ?? '1px',
      }}
    >
      <div className="icon">{icon}</div>
      <div className="text">
        <div className="text-inner">
          <Typography variant="recap" style={{ textAlign: 'left' }}>
            <Trans>{text}</Trans>
          </Typography>
        </div>
      </div>
      <If condition={page === 'dove' && pointOfSale !== undefined}>
        <Then>
          <></>
        </Then>
        <Else>
          <div className="change1">
            <Link variant="recap" onClick={() => action()}>
              <Trans>pages.recap.change</Trans>
            </Link>
          </div>
        </Else>
      </If>
    </div>
  );
});
