/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OpenHours, PointOfSale as POS } from '../interface/point-of-sale';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

export const pointOfSale = async (
  serviceId: string,
  Latitude: number | undefined,
  Longitude: number | undefined,
  firstStep: boolean
): Promise<POS[]> => {
  try {
    const { data } = await client.post(`/api/PointOfSale/${TOKEN() ?? ''}`, {
      Latitude,
      Longitude,
      serviceId: serviceId !== '' && !firstStep ? serviceId : undefined,
    });
    if ('data' in data) {
      return data.data;
    }
    return [];
  } catch (err) {
    console.trace(err);
    return [];
  }
};

export const usePointOfSale = (
  serviceId: string,
  Latitude: number | undefined,
  Longitude: number | undefined,
  firstStep: boolean | undefined = false,
  filterList?: string[],
  key = ''
) => {
  return useQuery({
    queryFn: async () =>
      await pointOfSale(serviceId, Latitude, Longitude, firstStep),
    // queryFn: async () => await pointOfSale(),
    queryKey: ['point-of-sale', serviceId, Latitude, Longitude, firstStep],
    retry: 2,
    retryDelay: 180000,
    select: (result: POS[]) => normalize(result, filterList),
    // select: normalize
  });
};

const normalize = (result: POS[], filterList?: string[]): POS[] => {
  const hours: OpenHours = {
    0: {
      day: 0,
      hours: {},
    },
    1: {
      day: 1,
      hours: {},
    },
    2: {
      day: 2,
      hours: {},
    },
    3: {
      day: 3,
      hours: {},
    },
    4: {
      day: 4,
      hours: {},
    },
    5: {
      day: 5,
      hours: {},
    },
    6: {
      day: 6,
      hours: {},
    },
  };

  const finalResult = [];

  if (result.length === 0) return [];

  let resultFiltered: POS[] = result;

  if (filterList !== undefined) {
    resultFiltered = result.filter((el) => {
      return filterList.some((f) => {
        return f === el.id;
      });
    });
  }

  for (const pointOfSale of resultFiltered) {
    const pos = JSON.parse(JSON.stringify(pointOfSale));
    const temp = JSON.parse(JSON.stringify(hours));
    for (const calendarDay of pointOfSale?.calendarDays ?? []) {
      temp[moment(calendarDay?.date).day() as keyof OpenHours].hours = {
        ...calendarDay?.hours,
      };
    }
    pos.openHours = temp;
    pos.open = -1;
    finalResult.push(pos);
  }

  return finalResult;
};
