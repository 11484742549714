import { Typography } from '@mui/material';
import parse from 'html-react-parser';
import { getClient } from '../../lib/info';

export const Terms = (): JSX.Element => {
  const client = getClient();

  return (
    <>
      <Typography variant="pageTitle">
        Informativa sul trattamento dei dati personali
      </Typography>
      <div className="body padding-top-m padding-bottom-l" id="body">
        <div className="default-page" style={{ textAlign: 'left' }}>
          {parse(client?.company?.privacyNotice ?? '<div></div>')}
        </div>
      </div>
    </>
  );
};
