/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import './conferma.css';

import { Divider, Typography, useTheme } from '@mui/material';
import hexToRgba from 'hex-to-rgba';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { If, Then, Else } from 'react-if';

import { MenuNew } from './components/menu';
import { ImieiDati, Service, When, Where } from './items';
import { resetType } from '../../store/appointment-type';
import { StaticRectangle } from '../../components/rectangle';
import { getIsModify } from '../../lib/info';
import { resetWhen } from '../../store/appointment-slot';
import { resetCoordinates } from '../../store/coordinates';
import { useAppDispatch } from '../../store/hooks';
import { resetPage } from '../../store/page';
import { resetWho } from '../../store/personal-data';
import { resetWhere } from '../../store/point-of-sale';
import { resetUser } from '../../store/user';
import { getAppointment } from '../../utils/appointment';

export const Conferma = (): JSX.Element => {
  const appointment = getAppointment();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const autoConfirmation = appointment?.isOnlineBooking ?? false;
  // getAppointmentId
  const isModifica = getIsModify();

  useEffect(() => {
    dispatch(resetType());
    dispatch(resetWhere());
    dispatch(resetWho());
    dispatch(resetWhen());
    dispatch(resetUser());
    dispatch(resetPage());
    dispatch(resetCoordinates());
  }, []);

  return (
    <>
      <div
        className="titleHeader"
        style={{
          backgroundColor: hexToRgba(
            theme.palette.primary.main,
            theme.palette.mode === 'dark' ? 0.3 : 0.12
          ),
          color: theme.palette.primary.main,
        }}
      >
        <div
          className="padding-top-s padding-bottom-s"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="pageTitle" fontWeight="500">
            <Trans>{'pages.conferma.title'}</Trans>
          </Typography>
          <Typography variant="pageTitle">
            <If condition={isModifica}>
              <Then>
                <Trans>pages.modifica.title2</Trans>
              </Then>
              <Else>
                <If condition={autoConfirmation}>
                  <Then>
                    <Trans>pages.conferma.title2</Trans>
                  </Then>
                  <Else>
                    <Trans>pages.conferma.title2NoAutoConfirmation</Trans>
                  </Else>
                </If>
              </Else>
            </If>
          </Typography>
        </div>
      </div>
      <div className="default-page padding-top-m">
        <div className="titlePage">
          <div className="padding-bottom-xs">
            <Typography variant="contentTitle1">
              <Trans>pages.conferma.your_request</Trans>
            </Typography>
          </div>
        </div>
        <div className="confirmation-container padding-bottom-xs padding-top-xs ">
          <StaticRectangle>
            <>
              <Service type={appointment!} />

              <Divider />
              <Where where={appointment!} />
              <Divider />

              <When
                appointmentSlot={appointment!}
                // action={() => addToCalendar()}
              >
                <MenuNew />
              </When>
              <Divider />

              <ImieiDati personalData={appointment!} />
            </>
          </StaticRectangle>
        </div>

        <div className="disclaimer">
          <Typography variant="contentDescription2">
            <Trans>pages.recap.disclaimer</Trans>
          </Typography>
        </div>
      </div>
    </>
  );
};
