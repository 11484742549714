/* eslint-disable @typescript-eslint/no-misused-promises, @typescript-eslint/no-unused-vars */
import './dati-personali.css';

import { Typography } from '@mui/material';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Trans } from 'react-i18next';
import { boolean, object, string } from 'yup';
import 'yup-phone-lite';

import { PersonalDataForm } from './components/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailValidation } from '../../api/email-validation';
import { ConfirmButton } from '../../components/confirmButton';
import { PageTitle } from '../../components/PageTitle';
import { Recaps } from '../../components/Recaps/DefaultRecap';
import { PersonalDataInterface } from '../../interface/personal-data';
import { getIndexOfStep } from '../../lib/info';
import { setAlert } from '../../store/alert';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { increment } from '../../store/page';
import { selectedPersonalData, setData } from '../../store/personal-data';

const schema = object().shape({
  name: string().required('Il nome è obbligatorio').default(''),
  surname: string().required('Il cognome è obbligatorio').default(''),
  email: string()
    .email('Email non valida')
    .required("l'email è obbligatoria")
    // .test('check domain', 'Email non valida', async (value) => {
    //   if (value !== undefined) {
    //     const data = await emailValidation(value)
    //     return data
    //   }
    //   return true
    // })
    .default(''),
  phone: string()
    .default('')
    .required('il telefono è obbligatorio')
    .phone('IT', 'Telefono non valido'),
  // eslint-disable-next-line no-useless-escape
  // .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, {
  //   message: 'Telefono non valido',
  //   excludeEmptyString: true
  // }),
  otherInfo: string().max(512, 'Il testo risulta troppo lungo').default(''),
  checkPrivacyNotice: boolean()
    .default(false)
    .oneOf([true], "Devi accettare l'informativa della privacy"),
});

export const DatiPersonali = (): JSX.Element => {
  const personalData = useAppSelector(selectedPersonalData);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { ...personalData },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = methods;
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<PersonalDataInterface | FieldValues> = async (
    data: any
  ): Promise<any> => {
    const validateMail = await emailValidation(data.email);
    if (validateMail) {
      dispatch(setData(data));
      dispatch(increment());
    } else {
      dispatch(
        setAlert({
          code: 'email_not_valid',
          type: 'warning',
          cancel: false,
        })
      );
    }
  };

  return (
    <div className="default-page">
      <Recaps index={getIndexOfStep('i-miei-dati')} />
      <div className="padding-bottom-xs padding-top-xs">
        <PageTitle title="pages.your_data.title" />
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PersonalDataForm control={control} watch={watch} />
          <div className="margin-top-xs">
            <Typography variant="contentDescription2">
              <Trans>pages.your_data.title4</Trans>
            </Typography>
          </div>
          <ConfirmButton
            title="pages.your_data.button"
            disabled={!isValid}
            type="submit"
          />
        </form>
      </FormProvider>
    </div>
  );
};
