/* eslint-disable @typescript-eslint/ban-types */
import './popup.css';

import WarningIcon from '@mui/icons-material/ErrorTwoTone';
import { Button, Modal, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import { Trans } from 'react-i18next';

interface PopUpCalcelProps {
  confirm: Function;
  cancel: Function;
}

export const PopUpCalcel = memo(function PopUpCalcel({
  confirm,
  cancel,
}: PopUpCalcelProps): JSX.Element {
  const theme = useTheme();

  return (
    <Modal
      open={true}
      sx={{ outline: 'none' }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div
        style={{
          backgroundColor: theme.palette.card.main,
        }}
        className="popup-cancel-container"
      >
        <WarningIcon
          style={{
            fontSize: '6.0rem',
            color: theme.palette.warning.main,
          }}
        />
        <div className=" padding-bottom-xs">
          <div style={{ textAlign: 'center' }}>
            <Typography variant="pageTitle">
              <Trans>errors.title.warning</Trans>
            </Typography>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="contentTitle2">
            <Trans>{"sei sicuro di voler cancellare l'appuntamento?"}</Trans>
          </Typography>
        </div>
        <div
          className="buttons padding-bottom-s"
          style={{ paddingTop: '40px' }}
        >
          <Button
            onClick={() => cancel()}
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            <Typography variant="button" style={{ padding: '10px 0' }}>
              <Trans>pages.cancella.cancel</Trans>
            </Typography>
          </Button>
          <Button variant="contained" onClick={() => confirm()} color="primary">
            <Typography variant="button" style={{ padding: '10px 0' }}>
              <Trans>pages.cancella.confirm</Trans>
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
});
