/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { typeOfAppointment as TOA } from '../interface/appointment-type';
import { OpenHours, PointOfSale as POS } from '../interface/point-of-sale';
import { User } from '../interface/users';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';
import moment from 'moment';

interface appointmentProps {
  serviceId?: string;
  pointOfSaleId?: string;
  userId?: string;
}

export const getPrecompileData = async ({
  serviceId,
  pointOfSaleId,
  userId,
}: appointmentProps): Promise<{
  pointOfSale?: POS;
  service?: TOA;
  user?: User;
}> => {
  try {
    const { data } = await client.post(
      `/api/ShopSign/${TOKEN() ?? ''}/precompile`,
      {
        pointOfSaleId,
        serviceId,
        userId,
      }
    );

    return {
      ...data?.data,
      pointOfSale: normalize(data.data?.pointOfSale),
    };
  } catch (err) {
    console.trace(err);
    return {};
  }
};

const normalize = (result?: POS): POS | undefined => {
  if (result === undefined || result === null) return undefined;
  const hours: OpenHours = {
    0: {
      day: 0,
      hours: {},
    },
    1: {
      day: 1,
      hours: {},
    },
    2: {
      day: 2,
      hours: {},
    },
    3: {
      day: 3,
      hours: {},
    },
    4: {
      day: 4,
      hours: {},
    },
    5: {
      day: 5,
      hours: {},
    },
    6: {
      day: 6,
      hours: {},
    },
  };

  const temp = { ...hours };
  for (const calendarDay of result?.calendarDays ?? []) {
    temp[moment(calendarDay?.date).day() as keyof OpenHours].hours = {
      ...calendarDay?.hours,
    };
  }

  return { ...result, open: -1, openHours: temp };
};
