/* eslint-disable @typescript-eslint/no-unused-vars */
import { ListPointOfSales } from '../../components/list-point-of-sales';
import '../../dove.css';
import { Map } from '../../components/map';
import { memo, useMemo, useRef } from 'react';
import { PointOfSale } from '../../../../interface';

interface DesktopResultProps {
  pointOfSales?: PointOfSale[];
  latitude?: number;
  longitude?: number;
}
export const DesktopResult = memo(function DesktopResult({
  pointOfSales = [],
  latitude,
  longitude,
}: DesktopResultProps): JSX.Element {
  const reference = useRef<HTMLDivElement>(null);

  const PointOfSales = useMemo((): JSX.Element => {
    return <ListPointOfSales pointOfSales={pointOfSales} />;
  }, [pointOfSales]);

  const Maps = (): JSX.Element => {
    return (
      <Map
        markers={pointOfSales}
        center={
          latitude !== undefined && longitude !== undefined
            ? { lat: latitude, lng: longitude }
            : undefined
        }
      />
    );
  };

  return (
    <div className="splitter-container">
      <div className="point-of-sale-list">{PointOfSales}</div>

      <div className="point-of-sale-map" ref={reference} id="test">
        <Maps />
      </div>
    </div>
  );
});
