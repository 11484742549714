/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Loader } from '@component/Loader'

import { Skeleton } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { nanoid } from '@reduxjs/toolkit';
import { PointOfSale } from '../../../../interface';
import { memo, useCallback } from 'react';
import { If, Then } from 'react-if';
import { CustomMarker } from './marker';

interface MapProps {
  markers: PointOfSale[];
  center?: {
    lat: number;
    lng: number;
  };
  height?: number;
}
export const Map = memo(function Map(props: MapProps): JSX.Element {
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: ''
    googleMapsApiKey: import.meta.env.VITE_NX_PUBLIC_GOOGLE_KEY ?? '',
  });
  const { markers, center } = props;
  const theme = useTheme();

  return isLoaded ? (
    <MapInner markers={markers} center={center} theme={theme} />
  ) : (
    <Skeleton
      variant="rectangular"
      height="100%"
      width="100%"
      style={{ minHeight: '400px', borderRadius: '8px' }}
      animation="wave"
    />
  );
});

interface MapInnerProps {
  markers: PointOfSale[];
  center?: {
    lat: number;
    lng: number;
  };
  theme: Theme;
}
export const MapInner = memo(function MapInner(
  props: MapInnerProps
): JSX.Element {
  const { markers, center, theme } = props;

  // const handleOnLoad = useCallback((map: google.maps.Map): void => {
  //   const bounds = new google.maps.LatLngBounds()
  //   console.log('CENTER', center)
  //   if (center === undefined) {
  //     if (markers.length > 0) {
  //       bounds.extend({
  //         lat: markers[0]?.latitude ?? 0,
  //         lng: markers[0]?.longitude ?? 0
  //       })
  //     }
  //   }
  //   // if (center !== undefined) bounds.extend(center)
  //   // markers.forEach(({ latitude = 0, longitude = 0 }) =>
  //   //   bounds.extend({ lat: latitude, lng: longitude })
  //   // )

  //   map.fitBounds(bounds)
  // }, [])

  const handleCenter = useCallback((): any => {
    if (center !== undefined) return center;

    if (markers.length > 0) {
      return {
        lat: markers[0]?.latitude ?? 0,
        lng: markers[0]?.longitude ?? 0,
      };
    }
    return {
      lat: 0,
      lng: 0,
    };
  }, [center, markers]);

  return (
    <div
      id="map_test"
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '80vh',
        // maxHeight: iFrame ?? false ? '600px' : '80vh',
        position: 'sticky',
        top: '80px',
      }}
    >
      <GoogleMap
        // onLoad={handleOnLoad}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          borderRadius: '8px',
        }}
        center={handleCenter()}
        options={{
          // minZoom: 13,
          streetViewControl: false,
          disableDefaultUI: true,
          zoomControl: true,
          styles: [
            {
              featureType: 'landscape.natural',
              elementType: 'geometry',
              stylers: [{ color: '#f3f3f3' }],
            },
          ],
        }}
        zoom={13}
      >
        <If condition={center !== undefined}>
          <Then>
            <Marker
              key={nanoid()}
              position={center !== undefined ? center : { lng: 0, lat: 0 }}
              onClick={() => null}
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: theme?.palette.primary.main,
                strokeColor: theme?.palette.primary.main,
              }}
            />
          </Then>
        </If>
        {markers.map((marker) => (
          <CustomMarker
            key={nanoid()}
            marker={marker}
            theme={theme}
            markers={markers}
          />
        ))}
      </GoogleMap>
    </div>
  );
});
