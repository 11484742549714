/* eslint-disable @typescript-eslint/no-unused-vars */
import '../../dove.css';

import { usePointOfSale } from '../../../../api/point-of-sale';
import { ConfirmButton } from '../../../../components/confirmButton';
import { PageTitle } from '../../../../components/PageTitle';
import { ListPointOfSales } from '../../../../features/dove/components/list-point-of-sales';
import { Subtitle } from '../../../../features/dove/components/subtitle';
import { isFirstStep } from '../../../../lib/info';
import { resetWhen } from '../../../../store/appointment-slot';
import { selectedCoordinates } from '../../../../store/coordinates';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { increment } from '../../../../store/page';
import {
  selectedCustomPointOfSaleId,
  resetWhere,
  selectedPointOfSale,
  setPointOfSaleFromCustomPointOfSaleId,
  selectedFilterCustomPointOfSaleList,
} from '../../../../store/point-of-sale';
import { responsiveWidth } from '../../../../store/responsive';
import { resetUser } from '../../../../store/user';
import { memo, useCallback, useDeferredValue, useEffect, useMemo } from 'react';
import { Else, If, Then } from 'react-if';

import { LoaderWhere } from '../../components/loader';

import { DesktopResult } from './desktop-result';
import { MobileResult } from './mobile-result';

interface FinalDoveProps {
  id: string;
  latitude?: number;
  longitude?: number;
  isGoogleMapsActive: boolean;
}
export const SearchResult = memo(function SearchResult({
  id,
  latitude,
  longitude,
  isGoogleMapsActive,
}: FinalDoveProps): JSX.Element {
  const { loading } = useAppSelector(selectedCoordinates);
  const selected = useAppSelector(selectedPointOfSale);
  const customPointOfSaleId = useAppSelector(selectedCustomPointOfSaleId);
  const filterCustomPointOfSaleList = useAppSelector(
    selectedFilterCustomPointOfSaleList
  );
  const width = useAppSelector(responsiveWidth);

  const { data, isLoading } = usePointOfSale(
    id,
    latitude,
    longitude,
    isFirstStep('dove'),
    filterCustomPointOfSaleList
  );
  const deferredValue = useDeferredValue(data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deferredValue !== undefined && deferredValue.length > 0) {
      const find = deferredValue.find((x) => x.id === customPointOfSaleId);
      if (find !== undefined) {
        dispatch(setPointOfSaleFromCustomPointOfSaleId(find as any));
      }
      if (selected.id !== '') {
        const find = deferredValue.find((x) => x.id === selected.id);
        if (find === undefined) {
          dispatch(resetWhere());
          dispatch(resetWhen());
          dispatch(resetUser());
        }
      }
    }
  }, [deferredValue]);

  const PointOfSales = useMemo(() => {
    if (deferredValue !== undefined) {
      return <ListPointOfSales pointOfSales={deferredValue} />;
    }
    return <div />;
  }, [deferredValue]);

  const confirm = useCallback(() => {
    dispatch(increment());
  }, []);

  const SpittedContainer = useMemo(() => {
    if (!isGoogleMapsActive) {
      return (
        <div className="default-page" style={{ minHeight: '220px' }}>
          <div className="point-of-sale-list">{PointOfSales}</div>
        </div>
      );
    } else if (width > 700) {
      return (
        <div className="splitted-page padding-top-xs">
          <DesktopResult
            pointOfSales={deferredValue}
            latitude={latitude}
            longitude={longitude}
          />
        </div>
      );
    }
    return (
      <div className="splitted-page padding-top-xs">
        <MobileResult
          pointOfSales={deferredValue}
          latitude={latitude}
          longitude={longitude}
        />
      </div>
    );
  }, [deferredValue, width]);

  return (
    <>
      <div>
        <PageTitle
          title={
            isGoogleMapsActive
              ? 'pages.where.title1'
              : 'pages.where.title1NoMaps'
          }
        />
      </div>

      <Subtitle isGoogleMapsActive={isGoogleMapsActive} />
      <div style={{ height: '25px' }} />
      <If condition={isLoading || loading}>
        <Then>
          <LoaderWhere />
        </Then>
        <Else>{SpittedContainer}</Else>
      </If>
      <div style={{ height: '3px' }} />

      <ConfirmButton
        // title="pages.where.button"
        page="dove"
        disabled={selected.id === undefined || selected.id === ''}
        action={() => confirm()}
      />
    </>
  );
});
