import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { Type, typeOfAppointment } from '../interface';

const initialState: Type = {
  value: {
    id: '',
    companyId: '',
    name: '',
    description: '',
    durationValue: 10,
    studio: false,
    prenotazioneOnline: false,
    tempo_buffer_prima: 0,
    tempo_buffer_dopo: 0,
    autoConfirmation: false,
  },
};

export const typeOfAppointmentSlice = createSlice({
  name: 'TypeOfAppointment',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<typeOfAppointment>) => {
      state.value = action.payload;
    },
    setTypeFromCustomServiceId: (
      state,
      action: PayloadAction<typeOfAppointment>
    ) => {
      state.value = action.payload;
      state.customServiceId = undefined;
    },
    setCustomServiceId: (state, action: PayloadAction<string | undefined>) => {
      state.customServiceId = action.payload;
    },
    resetCustomServiceId: (state) => {
      state.customServiceId = undefined;
    },
    resetType: () => {
      return initialState;
    },
  },
});

export const {
  setType,
  resetType,
  setTypeFromCustomServiceId,
  setCustomServiceId,
  resetCustomServiceId,
} = typeOfAppointmentSlice.actions;

export const selectedType = (state: RootState): typeOfAppointment =>
  state.typeOfAppointment.value;

export const selectedCustomServiceId = (state: RootState): string | undefined =>
  state.typeOfAppointment.customServiceId;

// eslint-disable-next-line import/no-default-export
export default typeOfAppointmentSlice.reducer;
