/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import moment from 'moment';
import { memo, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { If, Then } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';

import { ImieiDati, Service, When, Where } from './items';
import { getClient, getIndexOfStep, getUrl, setIsModify } from '../../lib/info';
import { getAppointment } from '../../api/get-appointment';
import { useAppDispatch } from '../../store/hooks';
import { setAppointmentId, setPage } from '../../store/page';
import { setAlert } from '../../store/alert';
import { setDateSlot, serHourSlot } from '../../store/appointment-slot';
import { setType } from '../../store/appointment-type';
import { setData } from '../../store/personal-data';
import { setPointOfSaleStudio } from '../../store/point-of-sale';
import { setUser } from '../../store/user';
import { setWhen } from '../../utils/appointment/when';

export const Modify = memo(function Modify(): JSX.Element {
  const {
    company: { minCancellationValue },
  } = getClient();
  const { appointmentId } = useParams();
  const { data } = getAppointment(appointmentId ?? '');
  const deferredValue = useDeferredValue(data);
  const dispatch = useAppDispatch();
  const { steps } = getClient();
  const navigate = useNavigate();

  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    setIsModify(true);
    if (appointmentId !== undefined) {
      dispatch(setAppointmentId(appointmentId));
    }
  }, [appointmentId, dispatch]);

  useEffect(() => {
    if (deferredValue !== undefined && deferredValue !== null) {
      const now = moment();
      const { startTime } = deferredValue;
      const duration = moment.duration(
        moment(startTime, 'YYYY-MM-DD HH:mm:ss').diff(now, 'hours')
      );
      if (duration < minCancellationValue) {
        setDisable(true);
        dispatch(
          setAlert({
            code: 'no_modify',
            type: 'warning',
            cancel: false,
          })
        );
        return;
      }

      dispatch(setType(deferredValue?.service));

      dispatch(
        setPointOfSaleStudio({
          activePoint: deferredValue?.pointOfSale,
          studioId: deferredValue?.studio?.id,
        })
      );

      const formattedDate = moment(
        deferredValue?.startTime,
        'YYYY-MM-DDTHH:mm:ss'
      );

      dispatch(setDateSlot(formattedDate.format('YYYY/MM/DD')));
      dispatch(serHourSlot(formattedDate.format('HH:mm')));

      setWhen({
        user:
          deferredValue?.user !== undefined
            ? deferredValue?.user?.id
            : undefined,
        serviceId: deferredValue?.service?.id,
        pointOfSaleId: deferredValue?.pointOfSale?.id,
        date: formattedDate.format('YYYY/MM/DD'),
        hour: formattedDate.format('HH:mm'),
      });

      dispatch(setUser(deferredValue?.user));
      dispatch(
        setData({
          name: data?.name ?? '',
          surname: data?.surname ?? '',
          email: data?.email ?? '',
          phone: data?.telephone ?? '',
          otherInfo: data?.description ?? '',
          externalCustomerCode: data?.externalCustomerCode ?? '',
          checkPrivacyNotice: true,
        })
      );
    }
  }, [data?.description, data?.email, data?.externalCustomerCode, data?.name, data?.surname, data?.telephone, deferredValue, dispatch, minCancellationValue]);

  const Titles = useMemo(() => {
    return (
      <div className="titlePage padding-bottom-s">
        <div>
          <Typography variant="contentTitle1">
            <Trans>pages.modifica.your_request1</Trans>
          </Typography>
        </div>
        <div>
          <Typography variant="contentDescription1">
            <Trans>pages.modifica.your_request2</Trans>
          </Typography>
        </div>
        {/* <div>
          <Typography variant="contentTitle1">
            <Trans>pages.modifica.your_request</Trans>
          </Typography>
        </div> */}
      </div>
    );
  }, []);

  return (
    <div className="default-page padding-bottom-l">
      {Titles}
      <If condition={deferredValue !== undefined && deferredValue !== null}>
        <Then>
          <Service
            type={deferredValue!}
            disable={disable}
            action={() => {
              const index = getIndexOfStep('appuntamenti');
              dispatch(setPage(index));
              // dispatch(setPage(0))
              navigate(getUrl(steps[index]));
            }}
          />
          <Where
            where={deferredValue!}
            disable={disable}
            action={() => {
              const index = getIndexOfStep('dove');
              dispatch(setPage(index));
              // dispatch(setPage(0))
              navigate(getUrl(steps[index]));
            }}
          />
          <When
            appointmentSlot={deferredValue!}
            disable={disable}
            action={() => {
              const index = getIndexOfStep('quando');
              dispatch(setPage(index));
              // dispatch(setPage(0))
              navigate(getUrl(steps[index]));
            }}
          />
          <ImieiDati
            personalData={deferredValue!}
            disable={disable}
            action={() => {
              const index = getIndexOfStep('i-miei-dati');
              dispatch(setPage(index));
              // dispatch(setPage(0))
              navigate(getUrl(steps[index]));
            }}
          />
        </Then>
      </If>
    </div>
  );
});
