/* eslint-disable @typescript-eslint/no-unused-vars */
import './week-picker.css';
import 'moment/locale/it';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  IconButton,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import {
  CSSProperties,
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { usAppointmentSlot } from '../../../../../api/appointment-slot';
import { DateInterface } from '../../../../../interface/date';
import { getClient } from '../../../../../lib/info';
import {
  selectedDate,
  setDateSlot,
} from '../../../../../store/appointment-slot';
import { selectedType } from '../../../../../store/appointment-type';
import { selectedPointOfSale } from '../../../../../store/point-of-sale';
import { selectedUser } from '../../../../../store/user';

interface WeekPickerProps {
  startDate: Moment;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setDates: Function;
  range: number;
  maxDay: Moment;
}

export const WeekPicker = memo(function WeekPicker({
  startDate,
  range,
  setDates,
  maxDay,
}: WeekPickerProps): JSX.Element {
  const {
    company: { isUserSelectable },
  } = getClient();
  const { id: type } = useAppSelector(selectedType);
  const { id: where } = useAppSelector(selectedPointOfSale);
  const user = useAppSelector(selectedUser);
  const dispatch = useAppDispatch();
  const selectedDay = useAppSelector(selectedDate);

  // const endDate = startDate.clone().add(range, 'days');
  const endDate = useMemo(
    () => startDate.clone().add(range, 'days'),
    [startDate, range]
  );
  const memoizedSetDates = useCallback((date) => setDates(date), [setDates]);

  const { data: deferredValue, isLoading } = usAppointmentSlot(
    isUserSelectable,
    type,
    where,
    startDate.format('YYYY/MM/DD'),
    endDate.format('YYYY/MM/DD'),
    user?.id
  );
  // const deferredValue = useDeferredValue(data);
  const [isPrevButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    if (
      !isLoading &&
      endDate.isBefore(maxDay) &&
      selectedDay !== undefined &&
      deferredValue !== undefined
    ) {
      if (isPrevButtonClicked) {
        if (selectedDay in deferredValue) {
          if (!deferredValue[selectedDay].active) {
            const temp = moment(selectedDay, 'YYYY/MM/DD').add(1, 'days');
            let d = temp.format('YYYY/MM/DD');

            while (d in deferredValue && !deferredValue[d].active) {
              temp.add(1, 'days');
              d = temp.format('YYYY/MM/DD');
            }

            if (!(d in deferredValue)) {
              return;
            } else {
              dispatch(setDateSlot(d));
            }
          }
        }
        // isPrevButtonCLicked = false;
        return;
      }
      if (selectedDay in deferredValue) {
        if (!deferredValue[selectedDay].active) {
          const temp = moment(selectedDay, 'YYYY/MM/DD').add(1, 'days');
          let d = temp.format('YYYY/MM/DD');

          while (d in deferredValue && !deferredValue[d].active) {
            temp.add(1, 'days');
            d = temp.format('YYYY/MM/DD');
          }

          if (!(d in deferredValue)) {
            memoizedSetDates(temp);
            dispatch(setDateSlot(d));
          } else {
            dispatch(setDateSlot(d));
          }
        }
      }
      // else {
      //   memoizedSetDates(endDate.add(1, 'days'));
      //   dispatch(setDateSlot(endDate.add(1, 'days').format('YYYY/MM/DD')));
      // }
    }
  }, [
    isPrevButtonClicked,
    deferredValue,
    dispatch,
    endDate,
    maxDay,
    selectedDay,
    memoizedSetDates,
  ]);

  const Dates = useMemo((): JSX.Element => {
    if (deferredValue !== undefined) {
      return <WeekPickerInner data={deferredValue} />;
    }
    return null;
  }, [deferredValue]);

  return (
    <div className="week-picker">
      <IconButton
        disabled={!moment().isBefore(startDate)}
        onClick={() => {
          setIsButtonClicked(true);
          const newStart = startDate.clone().subtract(range + 1, 'days');
          memoizedSetDates(newStart);
          dispatch(setDateSlot(newStart.format('YYYY/MM/DD')));
        }}
        className="date-container "
      >
        <KeyboardArrowLeftIcon style={{ fontSize: '35px' }} />
      </IconButton>

      <If condition={isLoading}>
        <Then>
          <LoadingDateContainer />
        </Then>
        <Else>{Dates}</Else>
      </If>
      <IconButton
        disabled={startDate.isAfter(maxDay)}
        onClick={() => {
          setIsButtonClicked(false);

          const newStart = startDate.clone().add(range + 1, 'days');
          memoizedSetDates(newStart);
          dispatch(setDateSlot(newStart.format('YYYY/MM/DD')));
        }}
        className="date-container "
      >
        <KeyboardArrowRightIcon style={{ fontSize: '35px' }} />
      </IconButton>
    </div>
  );
});

export const WeekPickerInner = memo(function WeekPickerInner({
  data,
}: {
  data?: DateInterface;
}): JSX.Element {
  const theme = useTheme();
  const selectedDay = useAppSelector(selectedDate);
  const dispatch = useAppDispatch();
  return (
    <If condition={data !== undefined}>
      <Then>
        {data !== undefined &&
          Object.keys(data).map((date: string) => {
            return (
              <DateContainer
                key={nanoid()}
                data={data[date]}
                date={date}
                theme={theme}
                dispatch={(date: string) => dispatch(setDateSlot(date))}
                selected={date === selectedDay}
              />
            );
          })}
      </Then>
    </If>
  );
});

interface DateContainerProps {
  data: any;
  date: string;
  theme: Theme;
  // eslint-disable-next-line @typescript-eslint/ban-types
  dispatch: Function;
  selected?: boolean;
}
// ? Date Component
const DateContainer = memo(function DateContainer({
  data,
  date,
  theme,
  dispatch,
  selected = false,
}: DateContainerProps): JSX.Element {
  const { daysOfWeek, day, active = false } = data;
  const dow: string = daysOfWeek.toString();
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    aspectRatio: '1/1',
  };
  if (selected) {
    style.backgroundColor = theme?.palette.primary.main;
    style.color = '#fff';
  }
  return (
    <IconButton
      disabled={active === false}
      onClick={() => dispatch(date)}
      style={style}
      className="date-container "
    >
      <Typography variant="body2">
        <Trans>{`subDaysOfWeek.${dow}`}</Trans>
      </Typography>
      <Typography variant="body1" fontWeight={700}>
        {day}
      </Typography>
    </IconButton>
  );
});

const LoadingDateContainer = memo(function LoadingDateContainer(): JSX.Element {
  const row = [];

  for (let i = 0; i <= 6; i++) {
    row.push(
      <IconButton key={nanoid()} className="date-container ">
        <Skeleton variant="circular" width="100%" height="100%" />
      </IconButton>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{row}</>;
});
