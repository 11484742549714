import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { eraseWhen } from '@utils/appointment/when'
import moment from 'moment';

import { RootState } from '.';
import { AppointmentSlot } from '../interface/appointment-slot';

const initialState: AppointmentSlot = {
  date: moment().startOf('day').format('YYYY/MM/DD'),
};

export const AppointmentSlotSlice = createSlice({
  name: 'AppointmentSlot',
  initialState,
  reducers: {
    setDateSlot: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
      state.hour = undefined;
    },
    serHourSlot: (state, action: PayloadAction<string>) => {
      state.hour = action.payload;
    },
    resetAppointmentSlot: (state) => {
      if (state.hour !== undefined) state.hour = undefined;
    },
    resetWhen: () => {
      // eraseWhen()
      return initialState;
    },
  },
});

export const { setDateSlot, serHourSlot, resetAppointmentSlot, resetWhen } =
  AppointmentSlotSlice.actions;

export const selectedDate = (state: RootState): string | undefined =>
  state.appointmentSlot.date;
export const selectedHour = (state: RootState): string | undefined =>
  state.appointmentSlot.hour;

export const selectedAppointmentSlot = (
  state: RootState
): {
  date?: string;
  hour?: string;
} => state.appointmentSlot;

// eslint-disable-next-line import/no-default-export
export default AppointmentSlotSlice.reducer;
