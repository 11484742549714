import { Navigate, useLocation, useParams } from 'react-router-dom';
import { searchToken } from '../../utils/token';

export const Redirect = (): JSX.Element => {
  console.log('REDIRECT TO');
  const param = useParams();

  if (searchToken(param.token ?? '')) {
    return (
      <Navigate
        to={`/${
          import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''
        }appuntamenti/${param.token ?? ''}`}
      />
    );
  }
  return (
    <Navigate
      to={`/${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''}not-found`}
    />
  );
};

export const RedirectCustom = (): JSX.Element => {
  const param = useParams();
  const { search } = useLocation();
  console.log('REDIRECT TO CUSTOM', param);

  if (searchToken(param.token ?? '')) {
    return (
      <Navigate
        to={`/${
          import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''
        }custom/appuntamenti/${param.token ?? ''}${search}`}
      />
    );
  }
  return (
    <Navigate
      to={`/${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''}not-found`}
    />
  );
};

export const NavigateTo = ({ navigate }: { navigate: string }): JSX.Element => {
  console.log('NAVIGATE TO', navigate);
  return (
    <Navigate
      to={`/${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''}not-found`}
    />
  );
};
