import { memo, useMemo } from 'react';

import './dove.css';
import { SearchResult } from './pages/result';
import { SearchAddress } from './pages/search';
import { Recaps } from '../../components/Recaps/DefaultRecap';
import { getClient, getIndexOfStep } from '../../lib/info';
import { selectedType } from '../../store/appointment-type';
import { selectedCoordinates } from '../../store/coordinates';
import { useAppSelector } from '../../store/hooks';

export const Dove = memo(function Dove(): JSX.Element {
  const { id: type } = useAppSelector(selectedType);
  const { coords, initial, loading } = useAppSelector(selectedCoordinates);

  const {
    company: { isGoogleMapsActive, isGoogleMapsSearchActive },
  } = getClient();

  const Content = useMemo(() => {
    if (!(isGoogleMapsActive ?? true) || !(isGoogleMapsSearchActive ?? true)) {
      return (
        <div>
          <SearchResult id={type} isGoogleMapsActive={isGoogleMapsActive} />
        </div>
      );
    }
    if (initial) return <SearchAddress />;
    else {
      return (
        <div>
          <SearchResult
            id={type}
            latitude={coords?.lat}
            longitude={coords?.lng}
            isGoogleMapsActive={isGoogleMapsActive}
          />
        </div>
      );
    }
  }, [
    coords?.lat,
    coords?.lng,
    initial,
    isGoogleMapsActive,
    isGoogleMapsSearchActive,
    type,
  ]);

  return (
    <>
      <div className="default-page" style={{ minHeight: 'fit-content' }}>
        <Recaps index={getIndexOfStep('dove')} />
      </div>
      {Content}
    </>
  );
});
