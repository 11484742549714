import { RouteObject } from 'react-router-dom';

import { CheckConferma, CheckPreviousStepCompletion } from './checks';
import { NavigateTo, Redirect, RedirectCustom } from './redirects';
import {
  LayoutOtherPages,
  LayoutConfirm,
  Layout,
} from '../../components/Layout';
import { Appuntamenti } from '../../features/appuntamenti';
import { Cancel } from '../../features/cancella';
import { Conferma } from '../../features/conferma';
import { Cookie } from '../../features/cookie';
import { DatiPersonali } from '../../features/dati-personali';
import { Dove } from '../../features/dove';
import { DownloadAppointment } from '../../features/download-appointment';
import { Modify } from '../../features/modify';
import { NotFound } from '../../features/not-found';
import { Privacy } from '../../features/privacy';
import { Quando } from '../../features/quando';
import { Recap } from '../../features/recap';
import { Terms } from '../../features/terms';

export const routes: RouteObject[] = [
  {
    path: import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? '/',
    children: [
      {
        path: 'download/:token/:appointmentId',
        element: <DownloadAppointment />,
      },
      {
        path: '',
        element: <LayoutOtherPages />,
        children: [
          {
            path: 'privacy/:token',
            element: <Privacy />,
          },
          {
            path: 'terms/:token',
            element: <Terms />,
          },
          {
            path: 'cookie/:token',
            element: <Cookie />,
          },
          {
            path: 'cancella/:token/:appointmentId',
            element: <Cancel />,
          },
          {
            path: 'modifica/:token/:appointmentId',
            element: <Modify />,
          },
        ],
      },
      {
        path: '',
        element: <LayoutConfirm />,
        children: [
          {
            path: 'conferma/:token',
            element: <CheckConferma />,
            children: [
              {
                path: '',
                element: <Conferma />,
              },
            ],
          },
        ],
      },
      {
        path: 'custom',
        element: <LayoutConfirm />,
        children: [
          {
            path: 'conferma/:token',
            element: <CheckConferma customPath />,
            children: [
              {
                path: '',
                element: <Conferma />,
              },
            ],
          },
        ],
      },
      {
        path: 'custom',
        element: <Layout customPath />,
        children: [
          {
            path: 'appuntamenti/:token',
            element: <Appuntamenti />,
          },
          {
            path: 'dove/:token',
            element: <CheckPreviousStepCompletion customPath name="dove" />,
            children: [
              {
                path: '',
                element: <Dove />,
              },
            ],
          },
          {
            path: 'quando/:token',
            element: <CheckPreviousStepCompletion customPath name="quando" />,
            children: [
              {
                path: '',
                element: <Quando />,
              },
            ],
          },
          {
            path: 'i-miei-dati/:token',
            element: (
              <CheckPreviousStepCompletion customPath name="i-miei-dati" />
            ),
            children: [
              {
                path: '',
                element: <DatiPersonali />,
              },
            ],
          },
          {
            path: 'riepilogo/:token',
            element: (
              <CheckPreviousStepCompletion customPath name="riepilogo" />
            ),
            children: [
              {
                path: '',
                element: <Recap />,
              },
            ],
          },
        ],
      },
      {
        element: <RedirectCustom />,
        path: `/${
          import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''
        }custom/:token`,
      },
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: 'appuntamenti/:token',
            element: <Appuntamenti />,
          },
          {
            path: 'dove/:token',
            element: <CheckPreviousStepCompletion name="dove" />,
            children: [
              {
                path: '',
                element: <Dove />,
              },
            ],
          },
          {
            path: 'quando/:token',
            element: <CheckPreviousStepCompletion name="quando" />,
            children: [
              {
                path: '',
                element: <Quando />,
              },
            ],
          },
          {
            path: 'i-miei-dati/:token',
            element: <CheckPreviousStepCompletion name="i-miei-dati" />,
            children: [
              {
                path: '',
                element: <DatiPersonali />,
              },
            ],
          },
          {
            path: 'riepilogo/:token',
            element: <CheckPreviousStepCompletion name="riepilogo" />,
            children: [
              {
                path: '',
                element: <Recap />,
              },
            ],
          },
        ],
      },
      {
        element: <Redirect />,
        path: `/${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''}:token`,
      },
      {
        path: 'not-found',
        element: <NotFound />,
      },
      {
        path: '',
        element: <NavigateTo navigate="inner ''" />,
      },
      {
        path: '*',
        element: <NavigateTo navigate="inner *" />,
      },
    ],
  },
  {
    path: '/',
    children: [
      {
        path: '',
        element: <NavigateTo navigate="outer " />,
      },
      {
        path: '*',
        element: <NavigateTo navigate="outer *" />,
      },
    ],
  },
];
