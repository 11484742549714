/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './hour-picker.css';

import { Typography, useTheme } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { usAppointmentSlotDetails } from '../../../../../api/appointment-slot-details';
import { getClient } from '../../../../../lib/info';
import {
  selectedDate,
  selectedHour,
  resetAppointmentSlot,
  serHourSlot,
} from '../../../../../store/appointment-slot';
import { selectedType } from '../../../../../store/appointment-type';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  resetPointOfSaleStudio,
  selectedPointOfSale,
} from '../../../../../store/point-of-sale';
import { selectedUser, setUser } from '../../../../../store/user';
import { AnimatePresence, motion } from 'framer-motion';
import hexToRgba from 'hex-to-rgba';
import moment from 'moment';
import {
  memo,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans } from 'react-i18next';

export const HourPicker = memo(function HourPicker(): JSX.Element {
  const {
    company: { maxBookingValue, isUserSelectable },
  } = getClient();
  const selectedDay = useAppSelector(selectedDate);
  const momentDaySelected = moment(selectedDay, 'YYYY/MM/DD');
  const maxDayValue = moment().add(maxBookingValue, 'hours');
  const { id: type } = useAppSelector(selectedType);
  const where = useAppSelector(selectedPointOfSale);
  const user = useAppSelector(selectedUser);
  const hourSelected = useAppSelector(selectedHour);
  const ref = useRef<HTMLDivElement>(null);
  const [h, setH] = useState<number>(0);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { data, isLoading } = usAppointmentSlotDetails(
    isUserSelectable,
    type,
    where.id,
    selectedDay,
    user?.id
  );
  const deferredValue = useDeferredValue(data);

  useEffect(() => {
    if (deferredValue !== undefined) {
      const find = deferredValue.findIndex((v) => v === hourSelected);

      if (find === -1) {
        dispatch(resetAppointmentSlot());
      }
    }
  }, [deferredValue, dispatch, hourSelected]);

  const HoursContainer = useMemo((): JSX.Element => {
    if (deferredValue !== undefined && deferredValue.length > 0) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {data?.map((hour: string) => (
            <div
              key={nanoid()}
              className="hour-container"
              style={{
                backgroundColor:
                  hourSelected !== hour
                    ? hexToRgba(
                        theme.palette.primary.main,
                        theme.palette.mode === 'dark' ? 0.3 : 0.12
                      )
                    : theme.palette.primary.main,
                color:
                  hourSelected !== hour ? theme.palette.text.primary : '#fff',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(serHourSlot(hour));
                if (!isUserSelectable) {
                  dispatch(setUser(undefined));
                  dispatch(resetPointOfSaleStudio());
                }
              }}
            >
              <Typography variant="body2">{hour}</Typography>
            </div>
          ))}
        </>
      );
    } else if (momentDaySelected.isSameOrAfter(maxDayValue)) {
      return (
        <Typography
          variant="contentDescription1"
          style={{ textAlign: 'center' }}
        >
          <Trans>pages.when.dates_no_programmed</Trans>
        </Typography>
      );
    }
    return (
      <Typography variant="contentDescription1" style={{ textAlign: 'center' }}>
        <Trans>pages.when.disclaimer</Trans>
      </Typography>
    );
  }, [
    data,
    deferredValue,
    dispatch,
    hourSelected,
    maxDayValue,
    momentDaySelected,
    theme.palette.mode,
    theme.palette.primary.main,
    theme.palette.text.primary,
  ]);

  const Content = useMemo(() => {
    return (
      <div style={{ minHeight: h }}>
        <div className="hour-picker-container" ref={ref}>
          {HoursContainer}
        </div>
      </div>
    );
  }, [h, HoursContainer]);

  return (
    <AnimatePresence>
      {!isLoading && (
        <motion.div
          initial={{ height: `${h}px`, overflow: 'hidden' }}
          animate={{
            height: 'auto',
            transition: {
              duration: 0.1,
              type: 'tween',
            },
          }}
          onAnimationComplete={() => {
            if (h !== (ref.current?.clientHeight ?? 0)) {
              setH(ref.current?.clientHeight ?? 0);
            }
          }}
          exit={{
            height: `${h}px`,
            transition: {
              duration: 0.1,
              type: 'tween',
            },
          }}
        >
          {Content}
        </motion.div>
      )}
    </AnimatePresence>
  );
});
