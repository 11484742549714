/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PersonalDataInterface } from '../interface/personal-data';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';

interface appointmentProps {
  serviceId: string;
  pointOfSaleId: string;
  personalData: PersonalDataInterface;
  startTime: string;
  endTime: string;
  userId?: string;
  studioId?: string;
  appointmentId?: string;
}

export const useAppointment = async ({
  serviceId,
  pointOfSaleId,
  personalData: {
    name,
    surname,
    email,
    phone: telephone,
    otherInfo: description,
    externalCustomerCode,
  },
  startTime,
  endTime,
  userId,
  studioId,
  appointmentId,
}: appointmentProps): Promise<string | undefined> => {
  try {
    // const { data } = await client.get('/api/Service/111')
    const { data } = await client.post(`/api/Appointment/${TOKEN() ?? ''}`, {
      pointOfSaleId,
      serviceId,
      userId,
      name,
      surname,
      email,
      telephone,
      description,
      startTime,
      endTime,
      studioId,
      externalCustomerCode,
      Id: appointmentId,
      IsCreation: appointmentId === undefined,
    });
    if ('data' in data) {
      return data.data;
    }
    return undefined;
  } catch (err) {
    console.trace(err);
    return undefined;
  }
};
