/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { User as U } from '../interface/users';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';
import { useQuery } from '@tanstack/react-query';

export const users = async (
  serviceId: string,
  PointOfSaleId: string
): Promise<U[]> => {
  try {
    const { data } = await client.get(`/api/User/${TOKEN() ?? ''}`, {
      params: { serviceId, PointOfSaleId },
    });

    if ('data' in data) {
      return data.data;
    }
    return [];
  } catch (err) {
    console.trace(err);
    return [];
  }
};

// export const pointOfSale = async (): Promise<POS[]> => {
//   return await new Promise((resolve, reject) => {
//     setTimeout(function () {
//       resolve([
//         {
//           id: '3fa85f64-5717-4562-b3fc-2c963f66af5454',
//           name: 'Ottica Demenego Treviso',
//           address: 'Viale della Repubblica, 243',
//           region: 'TV',
//           city: 'Treviso',
//           postal_code: '31100',
//           latitude: 45.69250588851229,
//           longitude: 12.245209260929109,
//           distance: 1.2
//         },
//         {
//           id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//           name: 'Ottica Demenego Montebelluna',
//           address: 'Via Piave, 148',
//           region: 'TV',
//           city: 'Montebelluna',
//           postal_code: '31044',
//           latitude: 45.78661079341194,
//           longitude: 12.062783964677369,
//           distance: 2.5
//         },
//         {
//           id: '3fa85f64-5717-4562-b3fc-2c963f665321',
//           name: 'Ottica Demenego Mestre',
//           address: 'Via Peppino Impastato, 34',
//           region: 'VE',
//           city: 'Venezia',
//           postal_code: '30174',
//           latitude: 45.522809970154576,
//           longitude: 12.226702346816614,
//           distance: 15
//         },
//         {
//           id: '3fa85f64-5717-4562-b3fc-2c963f6243242',
//           name: 'Ottica Demenego Mestre Centro Commerciale Le Barche',
//           address: 'P.za XXVII Ottobre, 1',
//           region: 'VE',
//           city: 'Venezia',
//           postal_code: '30172',
//           latitude: 45.498724337769474,
//           longitude: 12.24851406702063,
//           distance: 22.2
//         }
//       ])
//     }, 5000)
//   })
// }

export const useUsers = (
  serviceId: string,
  PointOfSaleId: string,
  key = ''
) => {
  return useQuery({
    queryFn: async () => await users(serviceId, PointOfSaleId),
    queryKey: ['users', serviceId, PointOfSaleId, key],
    retry: 2,
    retryDelay: 180000,
  });
};
