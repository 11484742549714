/* eslint-disable @typescript-eslint/no-unused-vars */
import { Map } from '../../components/map';
import { PointOfSale } from '../../../../interface';
import { Tabs, Tab, Box } from '@mui/material';
import { memo, SyntheticEvent, useMemo, useRef, useState } from 'react';

import { ListPointOfSales } from '../../components/list-point-of-sales';

interface MobileResultProps {
  pointOfSales?: PointOfSale[];
  latitude?: number;
  longitude?: number;
}

export const MobileResult = memo(function MobileResult({
  pointOfSales = [],
  latitude,
  longitude,
}: MobileResultProps): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<{
    index: number;
    initial: boolean;
  }>({
    index: 0,
    initial: true,
  });

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue({ index: newValue, initial: false });

    if (newValue === 1 && scrollRef.current !== null) {
      window.scrollTo({
        top: scrollRef.current.offsetTop ?? 0,
        left: scrollRef.current.offsetLeft ?? 0,
        behavior: 'smooth',
      });
      // scrollRef.current?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'center'
      // })
    }
  };

  const PointOfSales = useMemo((): JSX.Element => {
    return <ListPointOfSales pointOfSales={pointOfSales} />;
  }, [pointOfSales]);

  const Maps = useMemo((): JSX.Element => {
    if (!value.initial) {
      return (
        <Map
          markers={pointOfSales}
          center={
            latitude !== undefined && longitude !== undefined
              ? { lat: latitude, lng: longitude }
              : undefined
          }
        />
      );
    }
    return <></>;
  }, [pointOfSales, latitude, longitude, value.initial]);

  return (
    <div ref={scrollRef}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value.index}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Lista punti vendita" style={{ zIndex: '200' }} />
          <Tab label="Visualizza mappa" style={{ zIndex: '200' }} />
        </Tabs>
      </Box>

      <TabPanel value={value.index} index={0}>
        <div
          className="point-of-sale-list padding-top-s"
          // style={{ minHeight: '75vh' }}
        >
          {PointOfSales}
        </div>
      </TabPanel>

      <TabPanel value={value.index} index={1}>
        <div
          className="margin-top-s"
          style={{
            padding: '0px 5px 0px 5px',
            position: 'relative',
            height: '75vh',
            width: '100%',
          }}
        >
          {Maps}
        </div>
      </TabPanel>
    </div>
  );
});

interface TabPanelProps {
  children?: JSX.Element;
  index: number;
  value: number;
}
const TabPanel = memo(function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index } = props;

  return (
    <div style={{ display: value === index ? 'block' : 'none' }}>
      {/* <div
    // style={{
    //   visibility: value === index ? 'visible' : 'hidden',
    //   overflow: 'hidden'
    // }}
    > */}
      {children}
    </div>
  );
});
