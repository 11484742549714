/* eslint-disable @typescript-eslint/ban-types */

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { memo } from 'react';
import { AppointmentData } from '../../interface/appointment-data';
import { SplittedRecap } from '../../components/Recaps/SplittedRecap';
import { getClient } from '../../lib/info';

interface ServiceProps {
  type: AppointmentData;
  action: Function;
  disable: boolean;
}
export const Service = memo(function Service({
  type: { serviceName: name },
  action,
  disable,
}: ServiceProps) {
  return (
    <SplittedRecap
      stepTitle="pages.recap.appuntamenti"
      title={[name]}
      action={() => action()}
      actionTitle={disable ? '' : 'pages.recap.change'}
      icon={<VisibilityIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  );
});

interface WhereProps {
  where: AppointmentData;
  action: Function;
  disable: boolean;
}
export const Where = memo(function Where({
  where: {
    pointOfSaleAddress: address,
    pointOfSaleCity: city,
    pointOfSaleEmail: email,
    pointOfSaleTelephone: telephone,
    pointOfSaleName: name,
  },
  action,
  disable,
}: WhereProps) {
  const {
    company: { pointOfSale },
  } = getClient();
  const description: Array<{
    text: string;
    link?: string;
    adorment?: string;
  }> = [{ text: `${address} - ${city}` }];

  if (email !== undefined && email !== '') {
    description.push({
      text: email,
      link: `mailto:${email ?? ''}`,
      adorment: 'Email: ',
    });
  }
  if (telephone !== undefined && telephone !== '') {
    description.push({
      text: telephone,
      link: `tel:${telephone ?? ''}`,
      adorment: 'Tel: ',
    });
  }

  return (
    <SplittedRecap
      stepTitle="pages.recap.dove"
      title={[name]}
      description={description}
      action={() => action()}
      actionTitle={
        disable || pointOfSale !== undefined ? '' : 'pages.recap.change'
      }
      icon={<RoomIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  );
});

interface WhenProps {
  appointmentSlot: AppointmentData;
  action: Function;
  disable: boolean;
}
export const When = memo(function When({
  appointmentSlot: { startTime: date, userName: name, userSurname: surname },
  action,
  disable,
}: WhenProps) {
  const description: Array<{
    text: string;
    link?: string;
  }> = [];
  const {
    company: { isUserSelectable },
  } = getClient();

  if (isUserSelectable && name !== undefined && surname !== undefined) {
    description.push({ text: `Con ${name ?? ''} ${surname ?? ''}` });
  }
  const temp = moment(date, 'YYYY-MM-DDTHH:mm');

  return (
    <SplittedRecap
      stepTitle="pages.recap.quando"
      title={[
        `${temp.format('DD MMMM YYYY')} alle ore ${temp.format('HH:mm')}`,
      ]}
      description={description}
      action={() => action()}
      actionTitle={disable ? '' : 'pages.recap.change'}
      icon={<CalendarMonthIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  );
});

interface ImieiDatiProps {
  personalData: AppointmentData;
  action: Function;
  disable: boolean;
}
export const ImieiDati = memo(function ImieiDati({
  personalData: {
    name,
    surname,
    email,
    telephone: phone,
    description: otherInfo,
  },
  action,
  disable,
}: ImieiDatiProps) {
  const titles: string[] = [];
  const description: Array<{
    text: string;
    link?: string;
  }> = [];

  titles.push(`${name} ${surname}`);

  description.push({
    text: `Email: ${email ?? ''}`,
  });
  description.push({ text: `Tel: ${phone ?? ''}` });

  if (otherInfo !== undefined && otherInfo !== '') {
    description.push({ text: `Note: ${otherInfo ?? ''}` });
  }

  return (
    <SplittedRecap
      stepTitle="pages.recap.i-miei-dati"
      title={titles ?? []}
      description={description ?? []}
      action={() => action()}
      actionTitle={disable ? '' : 'pages.recap.change'}
      icon={<PersonIcon style={{ marginTop: '2px' }} color="primary" />}
    />
  );
});
