import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { User } from '../interface';

interface UserSliceInterface {
  activeUser: User | undefined;
}

const initialState: UserSliceInterface = {
  activeUser: undefined,
};

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.activeUser = action.payload;
    },
    resetUser: () => initialState,
  },
});

export const { setUser, resetUser } = UserSlice.actions;

export const selectedUser = (state: RootState): User | undefined =>
  state.user.activeUser;

// eslint-disable-next-line import/no-default-export
export default UserSlice.reducer;
