import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { resizeSteps } from '../utils/resize';

export interface responsiveSliceInterface {
  value: number;
}

const initialState: responsiveSliceInterface = {
  value: resizeSteps(window.innerWidth, 0),
};

export const responsiveSlice = createSlice({
  name: 'Responsive',
  initialState,
  reducers: {
    setResponsive: (state, action: PayloadAction<number>) => {
      const value: number = resizeSteps(action.payload, state.value);
      if (value !== 0) {
        state.value = resizeSteps(action.payload, state.value);
      }
    },
  },
});

export const { setResponsive } = responsiveSlice.actions;

export const responsiveWidth = (state: RootState): number =>
  state.responsive.value;

// eslint-disable-next-line import/no-default-export
export default responsiveSlice.reducer;
