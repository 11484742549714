/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AppointmentData } from '../interface/appointment-data';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';
import { useQuery } from '@tanstack/react-query';

export const appointment = async (
  appointmentId: string
): Promise<AppointmentData | undefined | null> => {
  try {
    // const { data } = await client.get('/api/Service/111')
    const {
      data: { data },
    } = await client.get(
      `/api/Appointment/${TOKEN() ?? ''}/${appointmentId ?? ''}`
    );
    const result = {
      ...data,
      pointOfSaleId: data?.pointOfSale?.email,
      pointOfSaleName: data?.pointOfSale?.name,
      pointOfSaleAddress: data?.pointOfSale?.address,
      pointOfSaleCity: data?.pointOfSale?.city,
      pointOfSaleEmail: data?.pointOfSale?.email,
      pointOfSaleTelephone: data?.pointOfSale?.telephone,
      serviceId: data?.service?.id,
      serviceName: data?.service?.name,
      serviceDescription: data?.service?.description,
      userId: data?.user?.id,
      userName: data?.user?.name,
      userSurname: data?.user?.surname,
    };
    return result;
  } catch (err) {
    console.trace(err);
    return null;
  }
};

export const getAppointment = (appointmentId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryFn: async () => await appointment(appointmentId),
    queryKey: ['get-appointment', `${appointmentId}`],
    retry: 2,
    retryDelay: 2000,
  });
};
