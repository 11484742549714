/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { LinearProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { nanoid } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { FC, memo, useCallback } from 'react';
import './progress-bar.css';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { responsiveWidth } from '../../store/responsive';
import { pageValue, setPage } from '../../store/page';
import { getIndexOfStep, getStepFromIndex, getUrl } from '../../lib/info';

interface ProgressBarInterface {
  appointment: string;
  where: string;
  when: string;
  your_data: string;
  riepilogo: string;
}

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
export const ProgressBar: FC<{ customPath?: boolean }> = memo(
  function ProgressBar({ customPath = false }): JSX.Element {
    const titles: ProgressBarInterface =
      i18next.t('progress_bar', {
        returnObjects: true,
      }) ?? ({} as any);
    const { value: page, percentage, steps } = useAppSelector(pageValue);
    const responsive = useAppSelector(responsiveWidth);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const confermaIndex = getIndexOfStep('conferma');

    const { search } = useLocation();

    const handleClick = useCallback(
      (index: number): void => {
        if (page === confermaIndex) return;

        let url = '';
        if (customPath) {
          url = getUrl(`custom/${getStepFromIndex(index)}${search}`);
        } else url = getUrl(`${getStepFromIndex(index)}`);

        if (url !== window.location.pathname) {
          dispatch(setPage(index));
        }
      },
      [page]
    );

    const getTitle = useCallback(
      (index: number, title: String) => {
        if (page < index) {
          return (
            <div
              key={nanoid()}
              className="item"
              style={{
                cursor: 'default',
                width: `${100 / steps.length}%`,
              }}
            >
              <Typography variant="progressBarTitle" color="text.disabled">{`${
                index + 1
              }. ${responsive > 800 ? title.toString() : ''}`}</Typography>
            </div>
          );
        }

        if (page > index) {
          return (
            <div
              key={nanoid()}
              className="item"
              style={{
                width: `${100 / steps.length}%`,
              }}
            >
              <Typography
                variant="progressBarTitle"
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(index)}
                color="primary"
              >{`${index + 1}. ${
                responsive > 800 ? title.toString() : ''
              }`}</Typography>
            </div>
          );
        }
        return (
          <div
            key={nanoid()}
            className="item"
            style={{
              cursor: 'default',
              width: `${100 / steps.length}%`,
            }}
          >
            <Typography variant="progressBarTitle">{`${index + 1}. ${
              responsive > 800 ? title.toString() : ''
            }`}</Typography>
          </div>
        );
      },
      [responsive, page, dispatch]
    );

    return (
      <div
        id="blu-progress-bar"
        data-testid="progress-bar"
        style={{ position: 'sticky', top: '0px', zIndex: 300 }}
      >
        {/* <div style={{ height: !inView ? '70px' : 0 }} /> */}
        <div
          className={'container'}
          style={{ backgroundColor: theme.palette.background.paper }}
        >
          <div className="items">
            {steps.map((title, index) => {
              return getTitle(
                index,
                titles[title as keyof ProgressBarInterface]
              );
            })}
          </div>
          <div className="progress-bar-container">
            <div
              className={`progress-bar-paddding${
                page === confermaIndex ? '-confirm' : ''
              }`}
              style={{ backgroundColor: theme.palette.primary.main }}
            />
            <div
              className={`progressBar${
                page === confermaIndex ? '-confirm' : ''
              }`}
            >
              <LinearProgress
                variant="determinate"
                value={percentage}
                sx={{
                  height: 10,
                  backgroundColor: 'rgba(187, 187, 187, 1)',
                }}
              />
            </div>
            <div
              className={`progress-bar-paddding${
                page === confermaIndex ? '-confirm' : ''
              }`}
              style={{ backgroundColor: 'rgba(187, 187, 187, 1)' }}
            />
          </div>
        </div>
      </div>
    );
  }
);
