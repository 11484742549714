import axios from 'axios';
import { store } from '../../store';
import { setAlert } from '../../store/alert';

const client = axios.create({
  // baseURL: 'https://booking.ilmioottico.it/',
  baseURL: import.meta.env.VITE_NX_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain',
  },
  timeout: 100000,
});

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    const data = err.response?.data;

    store.dispatch(
      setAlert({
        type: 'warning',
        cancel: false,
        code: data?.code ?? '999',
      })
    );

    console.error(err);
    return await Promise.resolve([]);
  }
);

export { client };
