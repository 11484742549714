/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import InfoIcon from '@mui/icons-material/Info';
import {
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { FC, memo, useCallback, useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
  useFormState,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { Trans } from 'react-i18next';

import './form.css';
import { getClient } from '../../../../lib/info';
import {
  selectedPersonalData,
  setCheckPrivacyNotice,
  setProperty,
} from '../../../../store/personal-data';
import { PersonalDataWhitoutPrivacyInterface } from '../../../../interface/personal-data';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { StaticRectangle } from '../../../../components/rectangle';
import { capitalizeFirstLetter } from 'apps/blubookingb2c/src/utils/CapitalizeFirstLetter';

interface InputProps {
  control: Control<FieldValues, any>;
  title: string;
  onChangeOverride?: Function;
  dispatch: Function;
  [x: string]: any;
}

const Input = memo(function Input({
  title,
  control,
  onChangeOverride,
  dispatch,
  ...otherConf
}: InputProps): JSX.Element {
  const { personalDataUrlParams } = getClient();
  const value = useWatch({ name: title });
  const [error, setError] = useState<boolean>(false);
  const { setValue, trigger } = useFormContext();
  const { errors } = useFormState();

  const handleOnChange = useCallback((event: any) => {
    let pass = true;
    let value = event.target.value;
    if (title === 'name' || title === 'surname')
      value = capitalizeFirstLetter(value);
    if (onChangeOverride !== undefined) {
      pass = onChangeOverride(value);
    }
    if (pass) {
      dispatch(
        setProperty({
          key: title as keyof PersonalDataWhitoutPrivacyInterface,
          value: value,
        })
      );
      setValue(title, value);
      trigger(title);
    }
  }, []);

  return (
    <div className="padding-bottom-xs">
      <Typography variant="contentDescription2">
        <Trans>{`pages.your_data.${title}`}</Trans>
      </Typography>

      <TextField
        value={value}
        id={title}
        onChange={handleOnChange}
        error={error && Boolean(errors[title]?.message)}
        helperText={error ? (errors[title]?.message as any) : undefined}
        type="text"
        fullWidth
        size="small"
        inputProps={{
          onBlur: () => {
            setError(true);
          },
          readOnly: (personalDataUrlParams as any)[title],
          ...otherConf?.inputProps,
        }}
        {...otherConf}
      />
    </div>
  );
});

interface OtherInfoInputProps {
  control: Control<FieldValues, any>;
  dispatch: Function;
  [x: string]: any;
}

const OtherInfoInput = memo(function Input({
  control,
  dispatch,
  ...otherConf
}: OtherInfoInputProps): JSX.Element {
  const [error, setError] = useState<boolean>(false);

  return (
    <Controller
      name="otherInfo"
      control={control}
      render={({ field: { ref, ...rest }, formState: { errors } }: any) => {
        return (
          <TextField
            {...rest}
            error={error && Boolean(errors.otherInfo?.message)}
            helperText={error ? errors.otherInfo?.message : undefined}
            errors={errors}
            onChange={(event) => {
              dispatch(
                setProperty({
                  key: 'otherInfo' as keyof PersonalDataWhitoutPrivacyInterface,
                  value: event.target.value,
                })
              );
              rest.onChange(event);
            }}
            type="text"
            fullWidth
            size="small"
            inputProps={{
              onBlur: () => {
                setError(true);
              },
            }}
            multiline
            rows={5}
            placeholder=""
          />
        );
      }}
    />
  );
});

export const PersonalDataForm = memo(function PersonalDataForm({
  control,
  watch,
}: {
  control: any;
  watch: UseFormWatch<any>;
}): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  // const notEditable = useWatch({ name: 'notEditable' })
  return (
    <>
      <div className="margin-bottom-s">
        <StaticRectangle>
          <div className="recess">
            <div className="padding-top-m padding-bottom-m">
              <div className="child-switch">
                <Typography
                  fontWeight={700}
                  variant="contentTitle2"
                  className="text-switch"
                >
                  <Trans>pages.your_data.title1</Trans>
                </Typography>
                <div className="switch">
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={1000}
                    title={
                      <div
                        style={{
                          backgroundColor: theme.palette.card.main,
                          border: `1px solid ${
                            theme.palette.primary.main ?? ''
                          }`,
                          borderRadius: '8px',
                          padding: '5px 10px',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Typography
                          variant="contentDescription2"
                          color="primary"
                        >
                          <Trans>pages.your_data.disclaimer</Trans>
                        </Typography>
                      </div>
                    }
                  >
                    <InfoIcon color="primary" style={{ fontSize: '30px' }} />
                  </Tooltip>
                </div>
              </div>

              <Input control={control} title="name" dispatch={dispatch} />
              <Input control={control} title="surname" dispatch={dispatch} />
              <Input
                control={control}
                title="email"
                onChangeOverride={(value: string) =>
                  value !== undefined && value.trim().length === value.length
                }
                dispatch={dispatch}
              />
              <Input
                control={control}
                title="phone"
                onChangeOverride={(value: string) => {
                  if (value === '' || value === undefined) return true;
                  if (value.match(/\d|\s|[+]/gm).length === value.length) {
                    return true;
                  }
                  return false;
                }}
                dispatch={dispatch}
              />
            </div>
          </div>
        </StaticRectangle>
      </div>

      <StaticRectangle>
        <div className="recess">
          <div className="padding-top-m padding-bottom-m">
            <div className="child-switch">
              <Typography
                variant="contentTitle2"
                fontWeight={700}
                className="text-switch"
              >
                <Trans>pages.your_data.title3</Trans>
              </Typography>
            </div>

            <div className="form-field-spacer2">
              <OtherInfoInput control={control} dispatch={dispatch} />
            </div>
          </div>
        </div>
      </StaticRectangle>

      <CheckPrivacyNotice control={control} />
    </>
  );
});

const CheckPrivacyNotice: FC<{ control: any }> = memo(
  function CheckboxPrivacyNotice({ control }): JSX.Element {
    const { checkPrivacyNotice } = useAppSelector(selectedPersonalData);
    const dispatch = useAppDispatch();
    const { token } = getClient();
    return (
      <div className="recess">
        <div className="padding-top-s padding-bottom-xs">
          <Controller
            name="checkPrivacyNotice"
            control={control}
            render={({
              field: { ref, ...rest },
              formState: { errors },
            }: any) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...rest}
                      checked={checkPrivacyNotice}
                      onChange={(event) => {
                        dispatch(setCheckPrivacyNotice(event.target.checked));
                        rest.onChange(event);
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ textAlign: 'left', pl: '20px' }}>
                      Letta e compresa{' '}
                      <Link
                        color="primary"
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                        // onClick={() => navigate(getUrl('terms'))}
                        target="_blank"
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        href={`${window.origin}/${
                          import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? ''
                        }terms/${token}`}
                      >
                        l’informativa sul trattamento dei dati personali
                      </Link>
                      , confermo la richiesta di prenotazione dell’appuntamento.
                    </Typography>
                  }
                />
              );
            }}
          />
        </div>
      </div>
    );
  }
);
