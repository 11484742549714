/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useTheme } from '@mui/material';
// import hexToRgba from 'hex-to-rgba'
import { memo } from 'react';
// import { Trans } from 'react-i18next'
import { If, Then, Else } from 'react-if';
// import { useLocation } from 'react-router-dom'

import './header.css';
import { ImgLogo } from './style';
import { getClient } from '../../lib/info';

const NormalHeader = memo(function NormalHeader(): JSX.Element {
  const {
    company: { logo, webSiteLink = '' },
    customTheme,
  } = getClient();
  const theme = useTheme();
  let link = webSiteLink;

  if (!link.includes('http')) {
    link = `//${webSiteLink}`;
  }

  return (
    <div data-testid="header">
      <a
        href={link}
        className="logoContainer"
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
        target="_black"
      >
        <ImgLogo
          height={customTheme?.logo?.height}
          width={customTheme?.logo?.width}
          src={logo ?? ''}
          alt="logo"
        />
      </a>
      {/* <div
        className="titleHeader"
        style={{
          backgroundColor:
            theme.palette?.landingTitle !== undefined
              ? theme?.palette?.landingTitle.main
              : hexToRgba(
                  theme.palette.primary.main,
                  theme.palette.mode === 'dark' ? 0.3 : 0.12
                ),
          color:
            theme?.palette?.landingTitle !== undefined
              ? theme?.palette?.landingTitle?.text
              : theme.palette.primary.main
        }}
      >
        <Typography variant="titleLanding">
          <Trans>title</Trans>
        </Typography>
      </div> */}
    </div>
  );
});

const SpecialHeader = memo(function ConfirmHeader(): JSX.Element {
  const {
    company: { logo, webSiteLink = '' },
    customTheme,
  } = getClient();
  const theme = useTheme();

  let link = webSiteLink;

  if (!link.includes('http')) {
    link = `//${webSiteLink}`;
  }

  return (
    <>
      <div data-testid="header">
        <a
          href={link}
          className="logoContainer"
          style={{
            backgroundColor: theme.palette.background.paper,
          }}
          target="_black"
        >
          <ImgLogo
            height={customTheme?.logo?.height}
            width={customTheme?.logo?.width}
            src={logo ?? ''}
            alt="logo"
          />
        </a>
      </div>
      {/* <If condition={name !== 'conferma'}>
        <Then>
          <div
            className="titleHeader"
            style={{
              backgroundColor: hexToRgba(
                theme.palette.primary.main,
                theme.palette.mode === 'dark' ? 0.3 : 0.12
              ),
              color: theme.palette.primary.main
            }}
          >
            <div
              className="padding-top-s padding-bottom-s"
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="pageTitle" fontWeight="500">
                <Trans>{`pages.${name}.title`}</Trans>
              </Typography>
              <Typography variant="pageTitle">
                <Trans>{`pages.${name}.title2`}</Trans>
              </Typography>
            </div>
          </div>
        </Then>
      </If> */}
    </>
  );
});

interface HeaderProps {
  special?: boolean;
}

export const Header = ({ special = false }: HeaderProps): JSX.Element => {
  return (
    <If condition={special}>
      <Then>
        <SpecialHeader />
      </Then>
      <Else>
        <NormalHeader />
      </Else>
    </If>
  );
};
