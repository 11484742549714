/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import './popup.css';

// import ErrorIcon from '@mui/icons-material/CancelTwoTone'
import WarningIcon from '@mui/icons-material/ErrorTwoTone';
// import InfoIcon from '@mui/icons-material/InfoTwoTone'
import {
  Button,
  Dialog,
  Link,
  Modal,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { memo, useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { useNavigate } from 'react-router-dom';
import { reset, selectedAlert } from '../../store/alert';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPage } from '../../store/page';
import { getIndexOfStep } from '../../lib/info';
import { selectedPointOfSale } from '../../store/point-of-sale';

export const PopUp = memo(function PopUp(): JSX.Element {
  const alert = useAppSelector(selectedAlert);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const Content = useMemo(() => {
    if (alert !== undefined) {
      if (alert.code === '001') {
        return (
          <PopUpFullInner
            {...alert}
            remove={() => dispatch(reset())}
            theme={theme}
          />
        );
      } else if (alert.code === '999' || alert.code === '005') {
        return (
          <GeneralErrorPopUp
            {...alert}
            remove={() => dispatch(reset())}
            theme={theme}
          />
        );
      } else {
        return (
          <PopUpInner
            {...alert}
            remove={() => dispatch(reset())}
            theme={theme}
          />
        );
      }
    }
    return null;
  }, [alert]);
  return <AnimatePresence>{Content}</AnimatePresence>;
});

interface PopUpInnerProps {
  code: string;
  type: string;
  cancel: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  remove: Function;
  theme: Theme;
}

const PopUpInner = memo(function PopUpInner({
  code,
  type,
  cancel,
  remove,
  theme,
}: PopUpInnerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    switch (code) {
      case '002':
        dispatch(setPage(getIndexOfStep('quando')));
        remove();
        break;
      case '001':
        remove();
        navigate('/not-found');
        break;
      case 'email_not_valid':
        // eslint-disable-next-line no-case-declarations
        const element = document.getElementById('email');
        if (element !== null) {
          element.focus();
        }
        remove();
        break;
      default:
        remove();
        break;
    }
  }, []);

  return (
    <Modal
      open={true}
      sx={{ outline: 'none' }}
      className="error-modal-container"
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div
        className="error-popup-container"
        style={{
          backgroundColor: theme.palette.card.main,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <WarningIcon
            style={{
              fontSize: '6.0rem',
              color: theme.palette.warning.main,
            }}
          />
        </div>
        <If condition={type !== 'info'}>
          <Then>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="pageTitle">
                <Trans>{`errors.title.${type}`}</Trans>
              </Typography>
            </div>
          </Then>
        </If>
        <div className="padding-bottom-s" style={{ textAlign: 'center' }}>
          <Typography variant="contentTitle2">
            <Trans>{`errors.${code}`}</Trans>
          </Typography>
        </div>
        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
          <If condition={code !== '999'}>
            <Then>
              <Button
                variant="contained"
                onClick={() => handleClick()}
                color="primary"
                sx={{
                  textTransform: 'unset !important',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  margin: 'auto',
                }}
              >
                <Typography variant="button">
                  <Trans>{`errors.buttons.${code}`}</Trans>
                </Typography>
              </Button>
            </Then>
            <Else></Else>
          </If>
        </div>
      </div>
    </Modal>
  );
});

const GeneralErrorPopUp = memo(function PopUpInner({
  code,
  type,
  cancel,
  remove,
  theme,
}: PopUpInnerProps): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pointOfSale = useAppSelector(selectedPointOfSale);

  return (
    <Modal
      open={true}
      sx={{ outline: 'none' }}
      className="error-modal-container"
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div
        className="error-popup-container"
        style={{
          backgroundColor: theme.palette.card.main,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <WarningIcon
            style={{
              fontSize: '6.0rem',
              color: theme.palette.warning.main,
            }}
          />
        </div>
        <If condition={type !== 'info'}>
          <Then>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="pageTitle">
                <Trans>{`errors.title.${type}`}</Trans>
              </Typography>
            </div>
          </Then>
        </If>
        <div className="padding-bottom-s" style={{ textAlign: 'center' }}>
          <div>
            <Typography variant="contentTitle2">
              <Trans>{`errors.${code}`}</Trans>
            </Typography>
          </div>
          <div>
            <Typography variant="contentTitle2">
              <Trans>{`errors.${code}2`}</Trans>
            </Typography>
            <If condition={pointOfSale.id !== ''}>
              <Then>
                <Typography fontWeight="bold" variant="contentTitle2">
                  <Trans>{` ${pointOfSale.name} `}</Trans>
                </Typography>
                {pointOfSale.address !== '' && pointOfSale.city !== '' && (
                  <>
                    <Typography variant="contentTitle2">
                      <Trans>{'con sede in '}</Trans>
                    </Typography>
                    <Typography fontWeight="bold" variant="contentTitle2">
                      <Trans>{`${pointOfSale.address} - ${pointOfSale.city}`}</Trans>
                    </Typography>
                  </>
                )}
                {(pointOfSale?.telephone !== '' ||
                  pointOfSale?.email !== '') && (
                  <>
                    <Typography variant="contentTitle2">
                      <Trans>{' ai seguenti recapiti: '}</Trans>
                    </Typography>
                    <li>
                      {pointOfSale?.telephone !== '' && (
                        <>
                          <Typography variant="contentTitle2">
                            <Trans>{'numero di telefono '}</Trans>
                          </Typography>
                          <Link
                            href={`tel:${pointOfSale?.telephone ?? ''}`}
                            fontWeight="bold"
                            variant="contentTitle2"
                          >
                            <Trans>{`${pointOfSale?.telephone ?? ''},`}</Trans>
                          </Link>
                        </>
                      )}
                    </li>
                    <li>
                      {pointOfSale?.email !== '' && (
                        <>
                          <Typography variant="contentTitle2">
                            <Trans>{'email '}</Trans>
                          </Typography>
                          <Link
                            href={`mailto:${pointOfSale?.email ?? ''}`}
                            fontWeight="bold"
                            variant="contentTitle2"
                          >
                            <Trans>{`${pointOfSale?.email ?? ''}`}</Trans>
                          </Link>
                        </>
                      )}
                    </li>
                  </>
                )}
              </Then>
            </If>
          </div>
          {code === '005' && (
            <Typography variant="contentTitle2">
              <Trans>{`errors.${code}3`}</Trans>
            </Typography>
          )}
        </div>
        <div style={{ textAlign: 'center', paddingTop: '20px' }}></div>
      </div>
    </Modal>
  );
});

const PopUpFullInner = memo(function PopUpInner({
  code,
  type,
  remove,
  theme,
}: PopUpInnerProps): JSX.Element {
  // const handleClick = useCallback(() => {
  //   switch (code) {
  //     case '002':
  //       dispatch(setPage(getIndexOfStep('quando')))
  //       remove()
  //       break
  //     case '001':
  //       remove()
  //       navigate('/not-found')
  //       break
  //     case 'email_not_valid':
  //       // eslint-disable-next-line no-case-declarations
  //       const element = document.getElementById('email')
  //       if (element !== null) {
  //         element.focus()
  //       }
  //       remove()
  //       break
  //     default:
  //       remove()
  //       break
  //   }
  // }, [])

  return (
    <Dialog
      open={true}
      sx={{ outline: 'none', backgroundColor: theme.palette.card.main }}
      fullScreen
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div
        className="error-popup-container"
        // style={{
        //   backgroundColor: theme.palette.card.main
        // }}
      >
        <If condition={type !== 'info'}>
          <Then>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="pageTitle">
                <Trans>{`errors.title.${type}`}</Trans>
              </Typography>
            </div>
          </Then>
        </If>
        <div className="padding-bottom-s" style={{ textAlign: 'center' }}>
          <Typography variant="contentTitle2">
            <Trans>{`errors.${code}`}</Trans>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
});
