/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { nanoid } from '@reduxjs/toolkit';
import { client } from '../lib/client';
import { TOKEN } from '../lib/info';

export const getConfiguration = async (): Promise<any> => {
  try {
    const {
      data: { data },
    } = await client.get(`/api/ShopSign/${TOKEN() ?? ''}`);

    return data !== undefined
      ? {
          ...data,
          favicon:
            data?.favicon && data?.favicon !== '' && data?.favicon !== 'null'
              ? `${data?.favicon}?cacheTime=${nanoid()}`
              : null,
          logo:
            data?.logo && data?.logo !== '' && data?.logo !== 'null'
              ? `${data?.logo}?cacheTime=${nanoid()}`
              : null,
        }
      : [];
  } catch (err) {
    console.trace(err);
    return [];
  }
};
