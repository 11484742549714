// import saveAs from 'file-saver'
import { createEvent, DateArray } from 'ics';
import moment from 'moment';
import { memo, useDeferredValue, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getClient } from '../../lib/info';
import { getAppointment } from '../../api/get-appointment';

export const DownloadAppointment = memo(
  function DownloadAppointment(): JSX.Element {
    const { appointmentId } = useParams();
    const { data } = getAppointment(appointmentId ?? '');
    const deferredValue = useDeferredValue(data);
    const {
      company: { webSiteLink = '' },
    } = getClient();

    let link = webSiteLink;

    if (!link.includes('http')) {
      link = `https://${webSiteLink}`;
    }

    useEffect(() => {
      const appointmentSlot = moment(
        deferredValue?.startTime,
        'YYYY-MM-DDTHH:mm:ss'
      ).format('YYYY/MM/DD/HH/mm/ss');
      const date = (appointmentSlot ?? '')
        .split('/')
        .map((d) => parseInt(d, 10))
        .flat();

      createEvent(
        {
          start: date as DateArray,
          duration: { minutes: deferredValue?.durationValue ?? 10 },
          title: deferredValue?.serviceName ?? '',
          description: deferredValue?.serviceDescription ?? '',
          location: `${deferredValue?.pointOfSaleName} - ${deferredValue?.pointOfSaleAddress}, ${deferredValue?.pointOfSaleCity}`,
          url: link,
          geo: {
            lat: deferredValue?.latitude ?? 0,
            lon: deferredValue?.longitude ?? 0,
          },
          categories: [],
          status: 'CONFIRMED',
          busyStatus: 'BUSY',
          organizer: {
            name: deferredValue?.pointOfSaleName,
            email: deferredValue?.pointOfSaleEmail,
          },
          attendees: [
            {
              name: `${deferredValue?.name ?? ''} ${
                deferredValue?.surname ?? ''
              }`,
              email: `${deferredValue?.email ?? ''}`,
              rsvp: true,
              partstat: 'ACCEPTED',
              role: 'REQ-PARTICIPANT',
            },
          ],
        },
        (error, value) => {
          if (error != null) {
            console.log(error);
          }

          //   const blob = new Blob([value], {
          //     type: 'text/calendar;charset=utf-8'
          //   })

          // saveAs(blob, 'event-schedule.ics')
          // window.open('', '_self', '')
          // window.close()
        }
      );
    }, [deferredValue, link]);

    return <div></div>;
  }
);
