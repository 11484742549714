import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import {
  PersonalDataInterface,
  PersonalDataWhitoutPrivacyInterface,
} from '../interface/personal-data';

const initialState: PersonalDataInterface = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  otherInfo: '',
  externalCustomerCode: '',
  checkPrivacyNotice: false,
};

export const PersonalDataSlice = createSlice({
  name: 'PersonalData',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PersonalDataInterface>) => {
      return action.payload;
    },
    setProperty: (
      state,
      action: PayloadAction<{
        key: keyof PersonalDataWhitoutPrivacyInterface;
        value: string;
      }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    setCheckPrivacyNotice: (state, action: PayloadAction<boolean>) => {
      state.checkPrivacyNotice = action.payload;
    },
    resetWho: () => initialState,
  },
});

export const { setData, setCheckPrivacyNotice, setProperty, resetWho } =
  PersonalDataSlice.actions;

export const selectedPersonalData = (state: RootState): PersonalDataInterface =>
  state.personalData;

// eslint-disable-next-line import/no-default-export
export default PersonalDataSlice.reducer;
