/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './modify.css';

import moment from 'moment';
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { If, Then } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';
import { PopUpCalcel } from './components/popup';
import { ImieiDati, Service, When, Where } from './items';
import { Typography, Divider, Button } from '@mui/material';
import { deleteAppointment } from '../../api/delete-appointment';
import { getAppointment } from '../../api/get-appointment';
import { ConfirmButton } from '../../components/confirmButton';
import { Mask } from '../../components/Mask';
import { StaticRectangle } from '../../components/rectangle';
import { getClient, getUrl } from '../../lib/info';
import { setAlert } from '../../store/alert';
import { useAppDispatch } from '../../store/hooks';
import {
  resetAppointmentId,
  setAppointmentId,
  setPage,
} from '../../store/page';

export const Cancel = memo(function Cancel(): JSX.Element {
  const { appointmentId } = useParams();
  const {
    company: { minCancellationValue },
  } = getClient();
  const { data } = getAppointment(appointmentId ?? '');
  const deferredValue = useDeferredValue(data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { steps } = getClient();

  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [mask, setMask] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    // const result = await deleteAppointment(appointmentId ?? '')
    // setConfirm(result)
    setOpen(true);
  }, []);

  const popUpClose = useCallback(async () => {
    setOpen(false);
    setMask(true);
    const result = await deleteAppointment(appointmentId ?? '');
    if (result) {
      setConfirm(result);
    }
    dispatch(resetAppointmentId());
  }, [appointmentId, dispatch]);

  const getIsCancel = useCallback(() => {
    if (deferredValue !== undefined && deferredValue !== null) {
      const now = moment();
      const { startTime } = deferredValue;
      const duration = moment.duration(
        moment(startTime, 'YYYY-MM-DD HH:mm:ss').diff(now, 'hours')
      );
      if (duration < minCancellationValue) {
        dispatch(
          setAlert({
            code: 'no_cancel',
            type: 'warning',
            cancel: false,
          })
        );

        return true;
      }
      return false;
    }
    return true;
  }, [deferredValue, dispatch, minCancellationValue]);

  useEffect(() => {
    if (appointmentId !== undefined) {
      dispatch(setAppointmentId(appointmentId));
    }
  }, [appointmentId, dispatch]);

  const Titles = useMemo(() => {
    return (
      <div className="titlePage padding-bottom-s">
        <div>
          <Typography variant="contentTitle1">
            <Trans>pages.cancella.your_request</Trans>
          </Typography>
        </div>
        <div>
          <Typography variant="contentDescription1">
            <Trans>pages.cancella.your_request2</Trans>
          </Typography>
        </div>
      </div>
    );
  }, []);

  const GetPopUp = useMemo(() => {
    if (open) {
      return (
        <PopUpCalcel
          confirm={async () => await popUpClose()}
          cancel={() => setOpen(false)}
        />
      );
    }
    return <></>;
  }, [open, popUpClose]);

  const Step1 = useMemo(() => {
    return (
      <div className="default-page">
        {mask && <Mask />}
        {GetPopUp}
        {Titles}
        <div className="confirmation-container padding-bottom-xs padding-top-xs ">
          <If condition={deferredValue !== undefined && deferredValue !== null}>
            <Then>
              <StaticRectangle>
                <>
                  <Service type={deferredValue!} />
                  <Divider />

                  <Where where={deferredValue!} />
                  <Divider />

                  <When appointmentSlot={deferredValue!} />
                  <Divider />

                  <ImieiDati personalData={deferredValue!} />
                </>
              </StaticRectangle>
            </Then>
          </If>
        </div>

        <ConfirmButton
          title="pages.cancella.button"
          disabled={getIsCancel()}
          variant="primary"
          action={() => handleClick()}
        />
      </div>
    );
  }, [mask, GetPopUp, Titles, deferredValue, getIsCancel, handleClick]);

  const Step2 = useMemo(() => {
    return (
      <div className="default-page">
        <div
          style={{
            height: 'fit-content',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div className="padding-top-s">
            <Typography variant="pageTitle">
              Appuntamento cancellato correttamente
            </Typography>
          </div>
          <div className="padding-top-xs padding-bottom-m">
            <Typography variant="contentTitle1" fontWeight={400}>
              {"Riceverai una mail di conferma dell'avvenuta cancellazione"}
            </Typography>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setPage(0));
              navigate(getUrl(steps[0]));
            }}
            data-testid="confirm-button"
            sx={{
              textTransform: 'unset !important',
              paddingTop: '10px',
              paddingBottom: '10px',
              margin: 'auto',
            }}
          >
            <div className="insideButton">
              <Typography variant="button">
                <Trans>{'Prenota un nuovo appuntamento'}</Trans>
              </Typography>
            </div>
          </Button>
        </div>
      </div>
    );
  }, [dispatch, navigate, steps]);

  return <>{!confirm ? Step1 : Step2}</>;
});
