import { client } from '../lib/client';

export const emailValidation = async (email: string): Promise<boolean> => {
  try {
    // const { data } = await client.get('/api/Service/111')
    const {
      data: { data },
    } = await client.get(`/api/Email/${email}`);
    return data;
  } catch (err) {
    // console.trace(err)
    return false;
  }
};
