import './quando.css';

import { useCallback } from 'react';
import { If, Then } from 'react-if';
import { DatePicker } from './components/date-picker';
import { SelectUser } from './components/select-user';
import { getClient, getIndexOfStep } from '../../lib/info';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { increment } from '../../store/page';
import { PageTitle } from '../../components/PageTitle';
import { ConfirmButton } from '../../components/confirmButton';
import { Recaps } from '../../components/Recaps/DefaultRecap';
import { selectedAppointmentSlot } from '../../store/appointment-slot';

export const Quando = (): JSX.Element => {
  const {
    company: { isUserSelectable },
  } = getClient();
  const appointmentSlot = useAppSelector(selectedAppointmentSlot);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(increment());
  }, [dispatch]);

  // test
  return (
    <div className="default-page">
      <Recaps index={getIndexOfStep('quando')} />
      <div className="padding-bottom-xs padding-top-xs">
        <PageTitle title="pages.when.title" />
      </div>
      <div className="padding-bottom-xm">
        <If condition={isUserSelectable}>
          <Then>
            <SelectUser />
          </Then>
        </If>
      </div>
      <div>
        <DatePicker />
      </div>
      <ConfirmButton
        title="pages.when.button"
        disabled={
          appointmentSlot?.date === undefined ||
          appointmentSlot?.hour === undefined
        }
        action={() => handleClick()}
      />
    </div>
  );
};
