/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './select-user.css';

import {
  Select as MuiSelect,
  Autocomplete as MUIAutoComplete,
  Box,
  createTheme,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useUsers } from '../../../../api/users';
import { StaticRectangle } from '../../../../components/rectangle';
import { User } from '../../../../interface';
import { resetAppointmentSlot } from '../../../../store/appointment-slot';
import { selectedType } from '../../../../store/appointment-type';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectedPointOfSale } from '../../../../store/point-of-sale';
import { selectedUser, setUser } from '../../../../store/user';
import { memo, useDeferredValue, useMemo, useCallback, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Else, If, Then } from 'react-if';

export const SelectUser = memo(function SelectUser(): JSX.Element {
  // const theme = useTheme()
  return (
    <StaticRectangle>
      <div style={{ padding: '10px 0px', textAlign: 'center' }}>
        <Typography variant="contentTitle2" textAlign="center" fontWeight="700">
          <Trans>pages.where.title2</Trans>
        </Typography>
        <div className="recess padding-top-xs">
          <Select />
        </div>
      </div>
    </StaticRectangle>
  );
});

const Select = memo(function Select(): JSX.Element {
  const { id: type } = useAppSelector(selectedType);
  const { id: where } = useAppSelector(selectedPointOfSale);
  const user = useAppSelector(selectedUser);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { data, isLoading } = useUsers(type, where);
  const deferredValue = useDeferredValue(data);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      if (
        event.target.value === null ||
        event.target.value === undefined ||
        event.target.value === 'null'
      ) {
        dispatch(setUser(undefined));
        return;
      }

      dispatch(setUser(JSON.parse(event.target.value)));

      // dispatch(resetAppointmentSlot())
    },
    [dispatch]
  );

  useEffect(() => {
    if (deferredValue !== undefined && deferredValue.length !== 0) {
      const find = deferredValue?.find((u) => u?.id === user?.id);
      if (find === undefined) {
        dispatch(setUser(undefined));
      }
    }
  }, [user, deferredValue, dispatch]);

  const Content = useMemo(() => {
    return (
      <MuiSelect
        value={user?.id ?? 'null'}
        fullWidth
        onChange={handleChange}
        displayEmpty
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: theme.palette.card.main,
              },
            },
          },
        }}
        renderValue={(value: any) => {
          return (
            <>
              {value !== null && value !== undefined && value !== 'null' ? (
                <Typography>{`${user?.name ?? ''} ${
                  user?.surname ?? ''
                }`}</Typography>
              ) : (
                <Typography>
                  <Trans>pages.when.any_doctor</Trans>
                </Typography>
              )}
            </>
          );
        }}
      >
        {deferredValue?.map((u) => (
          <MenuItem
            key={nanoid()}
            value={JSON.stringify({ ...u, sign: undefined })}
            // style={{ backgroundColor: theme.palette.card.main }}
          >{`${u?.name ?? ''} ${u?.surname ?? ''}`}</MenuItem>
        ))}
        <MenuItem
          key={nanoid()}
          value="null"
          // style={{ backgroundColor: theme.palette.card.main }}
        >
          <div style={{ height: '20px' }}>
            <Trans>pages.when.any_doctor</Trans>
          </div>
        </MenuItem>
      </MuiSelect>
    );
  }, [
    user?.id,
    user?.name,
    user?.surname,
    handleChange,
    theme.palette.card.main,
    deferredValue,
  ]);

  return (
    <div>
      <If condition={isLoading}>
        <Then>
          <Skeleton
            variant="rectangular"
            height="60px"
            style={{ borderRadius: '8px' }}
          />
        </Then>
        <Else>{Content}</Else>
      </If>
    </div>
  );
});

export const Autocomplete = memo(function Autocomplete(): JSX.Element {
  const { id: type } = useAppSelector(selectedType);
  const { id: where } = useAppSelector(selectedPointOfSale);
  const user = useAppSelector(selectedUser);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useUsers(type, where);
  const deferredValue = useDeferredValue(data);

  const customTheme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            '.MuiAutocomplete-option': {
              '&.Mui-focused': {
                backgroundColor: theme.palette.card.main,
              },
              '&[aria-selected="true"]': {
                backgroundColor: theme.palette.card.main,
              },
              '&.Mui-focused[aria-selected="true"]': {
                backgroundColor: theme.palette.card.main,
              },
            },
          },
          popper: {
            backgroundColor: theme.palette.card.main,
          },
          paper: {
            backgroundColor: theme.palette.card.main,
          },
        },
      },
    },
  });

  const Complete = useMemo(() => {
    if (deferredValue !== undefined && deferredValue.length !== 0) {
      const find = deferredValue?.find((u) => u?.id === user?.id);
      if (find === undefined) {
        dispatch(setUser(undefined));
      }
    }
    return (
      <MUIAutoComplete
        value={user}
        options={data ?? []}
        // disableClearable
        // readOnly
        disableClearable
        onChange={(_: any, newValue: User | undefined) => {
          dispatch(setUser(newValue));
          dispatch(resetAppointmentSlot());
        }}
        autoHighlight
        getOptionLabel={(option) =>
          `${option?.name ?? ''} ${option?.surname ?? ''}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option?.name ?? ''} {option?.surname ?? ''}
          </Box>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            name=""
            fullWidth
            style={{
              backgroundColor: theme.palette.card.main,
              cursor: 'pointer',
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    );
  }, [deferredValue, user, data, dispatch, theme.palette.card.main]);

  return (
    <If condition={isLoading}>
      <Then>
        <Skeleton></Skeleton>
      </Then>
      <Else>
        <ThemeProvider theme={customTheme}>{Complete}</ThemeProvider>
      </Else>
    </If>
  );
});
