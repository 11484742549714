/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link, Theme, Typography } from '@mui/material';
import { InfoWindow, MarkerF, OverlayView } from '@react-google-maps/api';
import { PointOfSale } from '../../../../interface';
import { getClient } from '../../../../lib/info';
import { resetWhen } from '../../../../store/appointment-slot';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  pointOfSaleDisplayed,
  selectedMarker,
  setPointOfSale,
  setPointOfSaleDisplayed,
} from '../../../../store/point-of-sale';
import { resetUser } from '../../../../store/user';
import { numberFormatter } from '../../../../utils/formatter';
import { memo, useMemo, useState } from 'react';
import { If, Then } from 'react-if';

interface CustomMarkerProps {
  marker: PointOfSale;
  markers: PointOfSale[];
  theme: Theme;
}

export const CustomMarker = memo(function CustomMarker(
  props: CustomMarkerProps
): JSX.Element {
  const {
    company: { logo },
  } = getClient();
  const { marker, theme, markers } = props;
  const selected = useAppSelector(selectedMarker);
  const seeOther = useAppSelector(pointOfSaleDisplayed);
  const dispatch = useAppDispatch();
  const [render, setRender] = useState<boolean>(false);

  const handleActiveMarker = (marker: PointOfSale): void => {
    if (marker.id !== selected) {
      dispatch(setPointOfSale(marker));
      dispatch(resetWhen());
      dispatch(resetUser());

      const indexPointOfSale = markers.findIndex(
        (m: PointOfSale) => m.id === marker.id
      );

      if (indexPointOfSale >= seeOther) {
        dispatch(setPointOfSaleDisplayed(indexPointOfSale + 1));
      }

      setTimeout(() => {
        const element = document.getElementById(`point-of-sale-${marker.id}`);
        if (element !== null) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }, 50);
    }
    // if (marker === activeMarker) {
    //   return
    // }
    // setActiveMarker(marker)
  };

  const setActiveMarker = (): void => null;

  const Info = useMemo(() => {
    if (render && selected === marker.id) {
      return (
        <InfoWindow
          onCloseClick={() => setActiveMarker()}
          options={{
            pixelOffset: new google.maps.Size(0, -40, 'px', 'px'),
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              justifyItems: 'left',
              textAlign: 'left',
              color: 'black',
            }}
          >
            <Typography variant="contentDescription1" fontWeight="700">
              {marker.name}
            </Typography>
            <If
              condition={
                marker?.distance !== null && marker?.distance !== undefined
              }
            >
              <Then>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'fit-content',
                    justifyItems: 'center',
                    fontSize: '10px',
                  }}
                >
                  <RoomIcon
                    color="primary"
                    style={{ fontSize: '12px', marginRight: '5px' }}
                  />
                  <span style={{ fontWeight: '500' }}>
                    {`${numberFormatter(marker.distance ?? 0, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })} Km da te`}
                  </span>
                </div>
              </Then>
            </If>

            <If
              condition={
                marker.address !== undefined &&
                marker.address !== '' &&
                marker.city !== undefined &&
                marker.city !== ''
              }
            >
              <Then>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'fit-content',
                    justifyItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  <DirectionsOutlinedIcon
                    color="primary"
                    style={{ fontSize: '17px', marginRight: '5px' }}
                  />

                  <Link
                    color="primary"
                    href={`http://maps.google.com/?q=${marker.latitude},${marker.longitude}`}
                    target="_black"
                    fontWeight="500"
                  >
                    {`${marker.address ?? ''} - ${marker.city ?? ''}`}
                  </Link>
                </div>
              </Then>
            </If>

            <If
              condition={
                marker.address !== undefined &&
                marker.address !== '' &&
                marker.city !== undefined &&
                marker.city !== ''
              }
            >
              <Then>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'fit-content',
                    justifyItems: 'center',
                    fontSize: '14px',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all',
                  }}
                >
                  <EmailIcon
                    style={{ fontSize: '17px', marginRight: '5px' }}
                    color="primary"
                  />

                  <Link
                    color="primary"
                    href={`mailto:${marker.email ?? ''}`}
                    fontWeight="500"
                  >
                    {marker.email ?? ''}
                  </Link>
                </div>
              </Then>
            </If>
            <If
              condition={
                marker.telephone !== undefined && marker.telephone !== ''
              }
            >
              <Then>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'fit-content',
                    justifyItems: 'center',
                    fontSize: '14px',
                  }}
                >
                  <PhoneIcon
                    style={{ fontSize: '17px', marginRight: '5px' }}
                    color="primary"
                  />
                  <Link
                    color="primary"
                    href={`tel:${marker.telephone ?? ''}`}
                    fontWeight="500"
                  >
                    {marker.telephone ?? ''}
                  </Link>
                </div>
              </Then>
            </If>
          </div>
        </InfoWindow>
      );
    }
    return null;
  }, [render, selected]);

  return (
    <>
      <MarkerF
        key={marker.id}
        onLoad={() => setRender(true)}
        position={{ lat: marker?.latitude ?? 0, lng: marker?.longitude ?? 0 }}
        visible={false}
      >
        {Info}
      </MarkerF>
      <OverlayView
        position={{ lat: marker?.latitude ?? 0, lng: marker?.longitude ?? 0 }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        zIndex={200}
      >
        <div
          style={{
            width: '50px',
            height: '50px',
            marginTop: '-45px',
            marginLeft: '-17px',
            cursor: 'pointer',
          }}
          onClick={() => handleActiveMarker(marker)}
        >
          <div
            style={{
              width: '35px',
              height: '35px',
              border: `4px solid ${theme.palette.primary.main}`,
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <img
              alt=""
              src={logo}
              style={{
                maxHeight: '20px',
                maxWidth: '20px',

                backgroundPosition: 'center center',
                margin: 'auto',
              }}
            />
          </div>
          <div
            style={{
              clipPath: 'polygon(100% 0, 0 0, 50% 100%)',
              height: '15px',
              width: '10px',
              backgroundColor: theme.palette.primary.main,
              marginLeft: '12px',
              marginTop: '-4px',
            }}
          />
        </div>
      </OverlayView>
    </>
  );
});
