import { Palette, Theme, styled } from '@mui/material';
import hexToRgba from 'hex-to-rgba';

const borderColor = (
  palette: Palette,
  selected = false,
  hover = false
): string | undefined => {
  if (selected || hover) {
    if (palette.card?.enableBorder !== undefined) {
      return palette.card?.enableBorder;
    }
    return palette.primary.main;
  }
  return palette.card.border;
};

const boxBackgroundColor = (
  palette: Palette,
  selected = false
): string | undefined => {
  if (selected) {
    if (palette.card?.enable !== undefined) {
      return palette.card?.enable;
    }
    return hexToRgba(
      palette.primary.main,
      palette.mode === 'dark' ? 0.3 : 0.12
    );
  }
  return palette.card.main;
};

interface InnerBoxProps {
  selected: boolean;
  borderWidth?: number;
  borderWidthEnable?: number;
}

export const InnerBox = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' &&
    prop !== 'borderWidth' &&
    prop !== 'borderWidthEnable',
})<InnerBoxProps>(
  ({
    theme: { palette },
    selected,
    borderWidth = 1,
    borderWidthEnable = 2,
  }) => ({
    width: '100%',
    height: 'fit-content',
    padding: selected ? 0 : Math.abs(borderWidthEnable - borderWidth),
    borderWidth: selected ? borderWidthEnable : borderWidth,
    borderStyle: 'solid',
    borderRadius: 'inherit',
    borderColor: borderColor(palette, selected),
    backgroundColor: boxBackgroundColor(palette, selected),
    '&:hover': {
      borderColor: borderColor(palette, selected, true),
      padding: 0,
      borderWidth: borderWidthEnable,
    },
  })
);

export const ParentBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    borderRadius,
    staticType = false,
  }: {
    staticType?: boolean;
    borderRadius: number;
  }) => ({
    borderRadius,
    height: 'fit-content',
    margin: 'auto',
    width: staticType ? '100%' : '98%',
    textAlign: 'left',
    cursor: staticType ? 'auto' : 'pointer',
  })
);

interface StaticInnerBoxProps {
  borderWidth: number;
}

export const StaticInnerBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'borderWidth',
})<StaticInnerBoxProps>(({ theme, borderWidth }) => ({
  borderRadius: 'inherit',
  backgroundColor: theme.palette.card.main,
  border: `${borderWidth}px solid ${theme.palette.card?.border ?? ''}`,
  height: '100%',
}));
