import hexToRgba from 'hex-to-rgba';

import { getConfiguration } from '../api/configuration';
import { Info, Theme } from '../interface';
import { history } from '../lib/history';
import {
  getClient,
  getIndexOfStep,
  getNextStep,
  isFirstStep,
  setClient,
} from '../lib/info';
import CustomTheme from '../lib/info/theme/index.json';

import { addFavIcon } from './favicon';
import { isInIframe } from './isIframe';
import { MetaTheme } from './meta-theme';
import { getShortLink, getToken } from './token';
import { getShortLinkData } from '../api/shop-sign-link';
import { getPrecompileData } from '../api/appointment-precompile';
import { client } from '../lib/client';
import { store } from '../store';
import { setCustomServiceId, setType } from '../store/appointment-type';
import { checkPages, setPage } from '../store/page';
import { setData } from '../store/personal-data';
import {
  resetWhere,
  setCustomPointOfSaleId,
  setFilterPointOfSaleList,
  setPointOfSaleStudio,
} from '../store/point-of-sale';
import { setUser } from '../store/user';

const getValue = (values: any, name: string, isFirst: boolean): string => {
  if (
    values &&
    name in values &&
    values[name] !== null &&
    values[name] !== ''
  ) {
    return `${isFirst ? '' : '&'}${name}=${values[name]}`;
  }
  return '';
};

const getParams = (values: any): string => {
  const params = `${getValue(values, 'pointOfSaleId', true)}${getValue(
    values,
    'serviceId',
    false
  )}${getValue(values, 'userId', false)}${getValue(
    values,
    'studioId',
    false
  )}${getValue(values, 'name', false)}${getValue(
    values,
    'surname',
    false
  )}${getValue(values, 'email', false)}${getValue(values, 'telephone', false)}`;
  if (params !== '') return `?${params}`;
  return params;
};

export const onInit = async (): Promise<Info> => {
  console.log('enter init');
  // get from cookie
  // get from url
  const data: Info = getClient();

  // IFRAME
  data.iFrame = isInIframe();

  // TOKEN
  data.token = getToken();
  data.theme = CustomTheme as Theme;
  let result: any = null;
  if (data.token === '') {
    const shortLink = getShortLink();
    if (shortLink === '') {
      history.replace(
        `${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? '/'}not-found`
      );
      return await new Promise<Info>((resolve) => resolve(data));
    }

    result = await getShortLinkData(shortLink);
    if (result === null) {
      return await new Promise<Info>((resolve) => resolve(data));
    }
    history.replace(
      `${import.meta.env.VITE_REACT_APP_INITIAL_PATH ?? '/'}custom/$${
        result?.token ?? ''
      }${getParams(result)}`
    );
    data.token = `$${result?.token ?? ''}`;
  }

  const dataCompany = await getConfiguration();

  if (
    'configurationJson' in dataCompany &&
    dataCompany?.configurationJson !== undefined &&
    dataCompany?.configurationJson !== '{}'
  ) {
    data.theme = JSON.parse(dataCompany.configurationJson);
  }

  if (
    'customConfigurationJson' in dataCompany &&
    dataCompany?.customConfigurationJson !== undefined &&
    dataCompany?.customConfigurationJson !== '' &&
    dataCompany?.customConfigurationJson !== '{}'
  ) {
    data.customTheme = JSON.parse(dataCompany.customConfigurationJson);
  }

  data.company = {
    ...data.company,
    ...dataCompany,
  };

  if (data?.company?.appointmentProcess === 1) {
    data.steps = [
      'dove',
      'appuntamenti',
      'quando',
      'i-miei-dati',
      'riepilogo',
      'conferma',
    ];
  } else {
    data.steps = [
      'appuntamenti',
      'dove',
      'quando',
      'i-miei-dati',
      'riepilogo',
      'conferma',
    ];
  }
  store.dispatch(checkPages());

  if (data.company.numberPointOfSales === 1) {
    try {
      const {
        data: { data: pointOfSales },
      } = await client.post(
        `/api/PointOfSale/${data.token?.substring(1) ?? ''}`,
        {}
      );

      if (pointOfSales.length > 0) {
        data.steps = [
          'appuntamenti',
          'quando',
          'i-miei-dati',
          'riepilogo',
          'conferma',
        ];
        data.company.pointOfSale = pointOfSales[0];
        store.dispatch(checkPages());
        store.dispatch(resetWhere());
      }
    } catch (error) {
      console.error(error);
    }
  }
  document.styleSheets[0].addRule(
    '::-webkit-scrollbar-thumb',
    `background: ${hexToRgba(
      data.theme?.palette.primary.main ?? '#1976D2',
      0.2
    )}`
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  MetaTheme(data.theme?.palette.primary.main!!);
  addFavIcon(data.company?.favicon);

  // MANAGE CUSTOM PARAMS
  let urlParams,
    pointOfSaleIds,
    pointOfSaleId,
    serviceId,
    userId,
    studioId,
    name,
    surname,
    email,
    telephone,
    externalCustomerCode;

  if (result === null) {
    urlParams = new URLSearchParams(window.location.search);
    pointOfSaleIds = urlParams.get('pointOfSaleIds') ?? undefined;
    pointOfSaleId = urlParams.get('pointOfSaleId') ?? undefined;
    serviceId = urlParams.get('serviceId') ?? undefined;
    userId = urlParams.get('userId') ?? undefined;
    studioId = urlParams.get('studioId') ?? undefined;
    name = urlParams.get('name') ?? '';
    surname = urlParams.get('surname') ?? '';
    email = urlParams.get('email') ?? '';
    telephone = urlParams.get('telephone') ?? '';
    externalCustomerCode = urlParams.get('externalCustomerCode') ?? '';
  } else {
    urlParams = new URLSearchParams(window.location.search);
    pointOfSaleIds = result?.pointOfSaleIds ?? undefined;
    pointOfSaleId = result?.pointOfSaleId ?? undefined;
    serviceId = result?.serviceId ?? undefined;
    userId = result?.userId ?? undefined;
    studioId = result?.studioId ?? undefined;
    name = result?.name ?? '';
    surname = result?.surname ?? '';
    email = result?.email ?? '';
    telephone = result?.telephone ?? '';
    externalCustomerCode = result?.externalCustomerCode ?? '';
  }

  if (pointOfSaleId !== undefined) {
    store.dispatch(setCustomPointOfSaleId(pointOfSaleId));
  }
  if (pointOfSaleIds !== undefined) {
    store.dispatch(setFilterPointOfSaleList(pointOfSaleIds?.split(',')));
  }
  if (serviceId !== undefined) {
    store.dispatch(setCustomServiceId(serviceId));
  }

  store.dispatch(
    setData({
      name,
      surname,
      email,
      phone: telephone,
      otherInfo: '',
      externalCustomerCode,
      checkPrivacyNotice: false,
    })
  );

  data.personalDataUrlParams = {
    name: name !== undefined && name !== null && name !== '',
    surname: surname !== undefined && surname !== null && surname !== '',
    email: email !== undefined && email !== null && email !== '',
    phone: telephone !== undefined && telephone !== null && telephone !== '',
    otherInfo: false,
  };

  setClient(data);

  if (serviceId !== undefined || pointOfSaleId !== undefined) {
    const precompileData = await getPrecompileData({
      serviceId,
      pointOfSaleId,
      userId,
    });

    if (
      precompileData?.pointOfSale !== undefined &&
      precompileData?.pointOfSale !== null
    ) {
      store.dispatch(
        setPointOfSaleStudio({
          activePoint: precompileData.pointOfSale,
          studioId,
        })
      );
    }

    if (
      precompileData?.service !== undefined &&
      precompileData?.service !== null
    ) {
      store.dispatch(setType(precompileData.service));
    }

    if (precompileData?.user !== undefined && precompileData?.user !== null) {
      store.dispatch(setUser(precompileData?.user));
    }

    if (
      precompileData?.service !== undefined &&
      precompileData?.service !== null &&
      precompileData?.pointOfSale !== undefined &&
      precompileData?.pointOfSale !== null
    ) {
      store.dispatch(setPage(getIndexOfStep('quando')));
    } else if (
      precompileData?.service !== undefined &&
      precompileData?.service !== null
    ) {
      if (isFirstStep('appuntamenti')) {
        store.dispatch(setPage(getIndexOfStep(getNextStep('appuntamenti'))));
      } else {
        store.dispatch(setPage(getIndexOfStep('dove')));
      }
    } else if (
      precompileData?.pointOfSale !== undefined &&
      precompileData?.pointOfSale !== null
    ) {
      if (isFirstStep('dove')) {
        store.dispatch(setPage(getIndexOfStep(getNextStep('dove'))));
      } else {
        store.dispatch(setPage(getIndexOfStep('appuntamenti')));
      }
    }
  }

  return await new Promise<Info>((resolve) => resolve(data));
};
